import React, { useEffect } from 'react';
import './App.css';

import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';
import { Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Route360ad from './www/360ad/HomeRoute';
import Route360ads from './www/360ads/HomeRoute';

function App() {

  const window_init = () => {
    // 在标准 375px 适配下，100px = 1rem;
    var PixelRatio = 1 / window.devicePixelRatio;

    // 动态设置视口
    // const meta = document.createElement('meta');
    // meta.name = 'viewport';
    // meta.content = `width=device-width, initial-scale=${PixelRatio}, minimum-scale=${PixelRatio}, maximum-scale=${PixelRatio}, user-scalable=no`;
    // document.head.appendChild(meta);

    function setSize() {
      // 动态设置字体大小，自适应
      const html = document.getElementsByTagName('html')[0];
      const pageWidth = html.getBoundingClientRect().width;
      console.log("----------------", document.getElementsByTagName('html')[0].getBoundingClientRect().width)
      html.style.fontSize = `${pageWidth / 15}px`;
    }

    setSize();
    window.addEventListener('resize', setSize, false); // 自动跳转不需要刷新页面
    window.addEventListener('orientationchange', setSize, false);

    // 清理事件监听器
    return () => {
      window.removeEventListener('resize', setSize);
      window.removeEventListener('orientationchange', setSize);
    };
  }

  useEffect(() => {
    console.log("Component mounted or updated");
    window_init();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route path='/360ad/*' element={<Route360ad />} />
          <Route path='/360ads/*' element={<Route360ads />} />
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
