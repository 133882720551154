export const hotGameList = [
  {
    id: "293",
    path: "/games/morelist/293_Bubble_Shooter_Pro",
    name: "Bubble Shooter Pro",
    icon: "game_pic/icon/bubble-shooter-pro-293.webp",
    logo: "game_pic/icon2/bubble-shooter-pro-293.webp",
    image: "game_pic/icon3/bubble-shooter-pro-293.webp",
    icon4: "game_pic/icon4/bubble-shooter-pro-293.webp",
    icon5: "game_pic/icon_large/bubble-shooter-pro-293.webp",
    icon6: "game_pic/icon6/bubble-shooter-pro-293-1.webp",
    playCount: "502148",
    rating: "5.0",
    description:
      "Bubble Shooter Pro is a captivating bubble shooter game where your goal is to clear the board by matching and popping bubbles of the same color. Aim carefully and shoot bubbles to create clusters of two or more of the same color, causing them to burst. The game features a variety of challenging levels, and the more bubbles you clear in a single shot, the higher your score. Be cautious; if you fail to eliminate bubbles, new ones will appear at the top, increasing the pressure. It's a classic bubble shooter experience that's hard to put down.",
    createdTime: "",
    url: "games/Puzzle_Bobble/",
    icon2Large: "game_pic/icon2_large/bubble-shooter-pro-293.webp",
    icon3Large: "game_pic/icon3_large/bubble-shooter-pro-293.webp",
    categoryName: "",
    categoryId: ",64,84,70,",
    intro:
      "Get ready for hours of highly addictive bubble-popping fun in Bubble Shooter Pro.",
    gamePlay:
      "Aim and shoot bubbles at the cluster of bubbles at the top, matching them with bubbles of the same color to make them pop and clear the board.",
    likeRatio: "89",
    likeCount: "192172",
    dislikeCount: "23751",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/bubble-shooter-pro-293.webp",
  },
  {
    id: "27",
    path: "/games/morelist/27_Candy_Time",
    name: "Candy Time",
    icon: "game_pic/icon/candy-time-27.webp",
    logo: "game_pic/icon2/candy-time-27.webp",
    image: "game_pic/icon3/candy-time-27.webp",
    icon4: "game_pic/icon4/candy-time-27.webp",
    icon5: "game_pic/icon_large/candy-time-27.webp",
    icon6: "game_pic/icon6/candy-match-27(1).webp",
    playCount: "518927",
    rating: "4.5",
    description:
      "Candy Time is a match-3 game that challenges you to reach the highest level by completing score tasks. Your objective is to swap the positions of candies on the board, creating matches of three or more identical candies to earn points. Each level presents a specific score target that you must achieve to progress. With a variety of levels and increasingly demanding tasks, Candy Time offers a satisfying and challenging match-3 experience.",
    createdTime: "",
    url: "games/CandyTime/",
    icon2Large: "game_pic/icon2_large/candy-time-27.webp",
    icon3Large: "game_pic/icon3_large/candy-time-27.webp",
    categoryName: "",
    categoryId: ",64,85,",
    intro:
      "In Candy Time, your mission is to achieve the highest possible level by completing score tasks in this match-3 game.",
    gamePlay:
      "Swap candy positions on the board to create matches of three or more identical candies and reach the required score for each level.",
    likeRatio: "86",
    likeCount: "182973",
    dislikeCount: "29787",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/candy-time-27.webp",
  },
  {
    id: "1027",
    path: "/games/morelist/1027_Coloruid",
    name: "Coloruid",
    icon: "game_pic/icon/coloruid-1027.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/coloruid-1027.webp",
    icon6: "game_pic/icon6/coloruid-1027.webp",
    playCount: "127980",
    rating: "4.5",
    description:
      "In Coloruid, players aim to unify the colors of smile boxes by modifying their colors with clicks. Plan your clicks carefully to minimize the number of moves required to achieve color unification. With each level presenting new challenges and obstacles, test your puzzle-solving skills and aim for efficiency in Color Fusion.\n",
    createdTime: "",
    url: "games/COLOSmiles1027AZBG/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",4,3,",
    intro:
      "Welcome to Coloruid, a challenging puzzle game where you must use the least number of clicks to modify the color of smile boxes, unifying all the different smile boxes into one color.\n",
    gamePlay:
      "Coloruid presents players with a series of smile boxes, each with a different color. The objective is to unify all the smile boxes into one color using the least number of clicks possible. Strategize your clicks to achieve color fusion and solve each level's puzzle.\n",
    likeRatio: "72",
    likeCount: "11978",
    dislikeCount: "4659",
    displayMethod: "",
    icon4Large: "",
  },
];
