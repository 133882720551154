import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import styles from "../../assert/styles/app.module.css";
import Footer from '../Footer';
import Header from '../Header';

export default function Contact() {

    useEffect(() => {
        // 组件挂载后，自动滚动到页面顶部
        window.scrollTo(0, 0);
    }, []); // 空数组依赖表示这个副作用只在组件挂载后执行一次

    return (
        <div className={styles.app}>
            <Header/>
            <div className="content">
                <div style={{ fontSize: ".96rem", color: "black", fontWeight: "bold", padding: ".8rem" }}>
                    Get in touch ✉️
                </div>
                <div style={{ fontSize: ".68rem", color: "#474747", lineHeight: "1rem", padding: "0.8rem", marginBottom: ".8rem" }}>
                    Have a question about bubblegame?
                </div>
                <div style={{ fontSize: ".68rem", color: "#474747", lineHeight: "1rem", padding: "0.8rem", marginBottom: ".8rem" }}>
                    We’d love to hear from you! 🤗
                </div>
                <div style={{ fontSize: ".68rem", color: "#474747", lineHeight: "1rem", padding: "0.8rem", marginBottom: ".8rem" }}>
                    Send us a message and we’ll get back to you as soon as possible:
                    <Link to={"/email-protection"}>
                        <span className="__cf_email__" >[email&#160;protected]</span>
                    </Link>
                </div>
            </div>

            {<Footer />}
        </div>
    )
}
