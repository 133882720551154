import React, { useEffect, useState } from "react";
import appStyle from "../../../assert/styles/app.module.css";
import styles from "./index.module.css";

import Footer from "../../../commponents/Footer";
import { gameList } from "../../../redux/360ads_game_data";
import Header from "../../../commponents/Header";
import { Link } from "react-router-dom";

import {setPlatform } from "../../../redux/platformSlice";
import { useDispatch } from "react-redux";

export default function Home360ads() {
  
  const dispatch = useDispatch();
  const [dataList, setDataList]:Array<any> = useState([]);
  
  useEffect(() => {
    document.body.style.overflow = "auto";
    dispatch(setPlatform("360ads"));
    setDataList([]);
    getData();
  }, []);

  useEffect(() => {
    
  }, [dataList]);

  const getData = async () => {
    dataList.push(...gameList.slice(dataList.length, dataList.length + 6))
    setDataList([...dataList])
  };


  return (
    <div id="app" className={appStyle.app}>
      <Header/>
      
    
      <div className={styles.hot_title}> &nbsp; &nbsp;HOT Games</div>
      <div className={styles.game_list}>
        {dataList.map((item:any, index:number) => {
          return (
            <>
              <div className={styles.game_item}>
                <Link to={`games/detail?id=${item.id}`}>
                
                <img className={styles.imgs} src={(`${item.path}/${item.icon}`)} alt=""/>
                </Link>
                <div className={styles.game_item_title}><span>{item.name}</span></div>
              </div>
            </>
          )
        })}
      </div>
      <div >AD</div> 
    
      <div className={styles.game_more_btn}>
        <span style={{fontSize: "0.56rem",color: "rgb(255, 255, 255)",cursor: "pointer",}} onClick={getData}>
          More Games
          <img src={require("../../../assert/imgs/more_icon.png")} alt="loading" className={styles.more_btn}/>
        </span>
      </div>

      {/* ====================== about footer============== */}
      {<Footer />}
    </div>
  );
}
