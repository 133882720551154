import { configureStore } from '@reduxjs/toolkit';
import platformReducer from './platformSlice';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 默认使用 localStorage


// 配置持久化
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, platformReducer);


const store = configureStore({
  reducer: {
    platform: persistedReducer
  },
});

const persistor = persistStore(store);

export { store, persistor };

