import {createSlice, PayloadAction} from '@reduxjs/toolkit'


export interface PlatformState {
  value: string
}

const initialState: PlatformState = {
    value: '360ad'
}

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setPlatform: (state:PlatformState, action: PayloadAction<string>) => {
        console.log('Action received:', action, state);
      state.value = action.payload
    },
    
    getPlatform: (state:any)  => {
        console.log('----------------------------------get')
      return state.value
    }
  }
})

export default platformSlice.reducer
export const {setPlatform, getPlatform} = platformSlice.actions