import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import gstyles from "../../assert/styles/app.module.css";
import styles from "./index.module.css";
import gstyle from "./gamelist.module.css";
import Footer from "../Footer";

import Header from "../Header";

export default function Detail() {

  const [gameInfo, setGameInfo]:any = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlay, SetIsPlay] = useState(false)

  const [moreGamgesRowList, setMoreGamgesRowList]: Array<any> = useState([]);
  const [moreGamgesListUI, setMoreGamgesListUI]: Array<any> = useState([])
  
  const [gameList, setGameList]:any = useState([]);

  const location = useLocation();
  const init = () => { 
    // 使用 URLSearchParams 来解析查询字符串
    const queryParams = new URLSearchParams(location.search);
    // 获取具体的查询参数
    const id = queryParams.get("id");

    const gi = gameList.find((item:any) => item.id === `${id}`);
    setGameInfo(gi);
  };
  

  const [prefix, setPrefix]:any = useState('')
  
  useEffect(()=>{
    const path = location.pathname
    const routePath = path.substring(0, path.indexOf('/', 1));
    setPrefix(routePath)

    if(routePath ==='/360ad'){
      import ("../../redux/360ad_game_data").then((module) => {
        console.log(module)
        setGameList(module.gameList);
      })
    }else if(routePath === '/360ads'){
      import("../../redux/360ads_game_data").then((module) => {
        setGameList(module.gameList);
      })
    }
    init();
  },[location])
  
  useEffect(() => {
    init();
    addMoreGames();
  }, [gameList]);
  
  useEffect(() => {
    // 组件挂载后，自动滚动到页面顶部
    window.scrollTo(0, 0);

    animateProgressBar();
  }, []); // 空数组依赖表示这个副作用只在组件挂载后执行一次
  
  useEffect(() => {
    UImoreGamesList();
  }, [moreGamgesRowList]);

  
  // 渲染更多游戏
  const UImoreGamesList = async () => {
    if (moreGamgesRowList.length / 6 < 1) return;
    const item = (fanzhuan: string, list: Array<any>) => {
      return (
        <div className={gstyle.game_item} key={moreGamgesListUI.length}>
          <div
            className={`${gstyle.game_item_top} ${fanzhuan.length > 0 ? gstyle.fanzhuan : ""}`}
          >
            <div className={`${gstyle.game_item_top_left} ${gstyle.pos_rela}`}>
              <Link to={`${prefix}/games/detail?id=${list[0].id}`}>
                <img src={(`${list[0].path}/${list[0].icon}`)} alt="loading" className={gstyle.big_img} />
              </Link>
            </div>
            <div className={gstyle.game_item_top_right}>
              <div className={gstyle.game_item_top_right_item}>
                <Link to={`${prefix}/games/detail?id=${list[1].id}`}>
                  <img src={(`${list[1].path}/${list[1].icon}`)} alt="loading" className={gstyle.small_img} />
                </Link>
              </div>
              <div className={gstyle.game_item_top_right_item}>
                <Link to={`${prefix}/games/detail?id=${list[2].id}`}>
                  <img src={(`${list[2].path}/${list[2].icon}`)} alt="loading" className={gstyle.small_img} />
                </Link>
              </div>
            </div>
          </div>
          <div className={gstyle.game_item_bottom}>
            <div>
              <Link to={`${prefix}/games/detail?id=${list[3].id}`}>
                <img src={(`${list[3].path}/${list[3].icon}`)} alt="loading" className={gstyle.small_img} />
              </Link>
            </div>
            <div>
              <Link to={`${prefix}/games/detail?id=${list[4].id}`}>
                <img src={(`${list[4].path}/${list[4].icon}`)} alt="loading" className={gstyle.small_img} />
              </Link>
            </div>
            <div>
              <Link to={`${prefix}/games/detail?id=${list[5].id}`}>
                <img src={(`${list[5].path}/${list[5].icon}`)} alt="loading" className={gstyle.small_img} />
              </Link>
            </div>
          </div>
          {/* todo list 待添加 */}
          <div>AD</div>
        </div>
      );
    };

    const flag = moreGamgesListUI.length % 2 === 0 ? "fanzhuan" : "";

    moreGamgesListUI.push(item(flag, moreGamgesRowList));
    setMoreGamgesListUI([...moreGamgesListUI]);
  };

  // 加载动画
  const animateProgressBar = () => {
    let duration = 3000; // 进度条动画持续时间为3秒
    let interval = 50; // 更新间隔为50毫秒
    let steps = duration / interval;
    let step = 100 / steps;
    let count = 0;
    let timer = setInterval(() => {
        count += step;
        setProgress(count);
        if (count >= 100) {
            setIsLoading(false);
            clearInterval(timer);
        }
    }, interval);
  }

  const addMoreGames = ()=>{
    const start = moreGamgesListUI.length * 6;
    const end = start + 6;
    const len = gameList.length;
    moreGamgesRowList.length = 0
    if (end > len) return;
    
    moreGamgesRowList.push(...gameList.slice(start, end))
    setMoreGamgesRowList([...moreGamgesRowList])
  }


  return (
    <div className={gstyles.app}>
      <Header></Header>
      {/**  game detail */}
      <div className={`${styles.game_detail} pos_rela`}>
        <div className={styles.game_title}>{gameInfo?.name}</div>
        { gameInfo?
          <img
          alt=""
          src={(`${gameInfo?.path}/${gameInfo?.icon5}`)}
          style={{ width: "13.4rem", height: "8.94rem" }}
        /> : null
        }
        {
          isLoading?(
          <div className={styles.loading} >
            <div className={styles["progress-container"]}>
              <div className={styles["progress-bar"]} style={{ width: progress + "%" }}>
                {progress.toFixed(0)}%
              </div>
            </div>
          </div>
          ) : (
            <div className={styles.play_btn} onClick={() => {
              SetIsPlay(!isPlay)
              document.body.style.overflow = "hidden";
              }}>
              <img
                alt=""
                src={require("../../assert/imgs/play_btn.png")}
                className={styles.play_btn_image}
              />
            </div>
          )
        }

        <div className={styles.toolbar}>
          <div
            style={{
              width: "200rpx",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div className={styles.like_item}>
              <img
                alt=""
                className={styles.like_item_image}
                src={require("../../assert/imgs/like_icon.png")}
                onClick={() => {}}
              />

              <span style={{ marginTop: "10rpx" }}>{gameInfo?.likeCount}K</span>
            </div>
            <div className={styles.like_item}>
              <img
                alt=""
                className={styles.like_item_image}
                src={require("../../assert/imgs/favorite_icon.png")}
                onClick={() => {}}
              />
              <span style={{ marginTop: "10rpx" }}>
                {gameInfo?.favoriteCount}K
              </span>
            </div>
          </div>
        </div>
      </div>

      <div style={{width: "100%",margin: "10px 10px",display: "flex",justifyContent: "center",}}>
        <button className={styles.install} id="install-button" onClick={(e) => {}}>Install</button>
      </div>
          
      {/* <!-- google ad --> */}
        {/* <div style={{width: "14rem", fontSize: ".72rem", textAlign: "center"}} className="gads" >
            AD
            <div style={{minHeight: "4rem"}}>
                <ins className="adsbygoogle" style={{display:"block"}} data-ad-client="ca-pub-2955823384144718"
                    data-ad-slot="" data-ad-format="auto" data-full-width-responsive="true"></ins>
            </div>
        </div> */}
      

      {/* <!--more game list-- > */}
      <div className={styles.more_games}>
        <div style={{ width: "12.8rem" }}>
          <div className={gstyle.more_game_list}> {moreGamgesListUI} </div>
        </div>
      </div>

      <div className={styles.game_more_btn}>
        <span style={{fontSize: "0.56rem",color: "rgb(255, 255, 255)",cursor: "pointer",}} onClick={addMoreGames}>
          More Games
          <img src={require("../../assert/imgs/more_icon.png")} alt="loading" className={styles.more_btn}/>
        </span>
      </div>
      
      <div style={{width: "100%", height: "1rem"}}></div>
      {
      isPlay?(
      <div className={styles.play_game}>
        <Link to={`${prefix}/`} onClick={()=>{document.body.style.overflow = "auto";}}>
            <div className={styles.left_back}>
                <span style={{color: "#fff", fontSize: ".56rem", fontWeight: "bold"}}>{"<"} </span>
                        <img className={styles.left_back_image} alt="loading" src={
                          (`${gameInfo?.path}/${gameInfo?.icon}`)} />
            </div>
        </Link>

          <iframe src={gameInfo.path+"/"+gameInfo?.url} title={gameInfo?.name} style={{ border: "none" }} width="100%" height="100%"></iframe>
      </div>
        ):null
      }

      {<Footer />}
    </div>
  );
}
