export const gameList = [
  {
    id: "130",
    path: "/games/morelist/130_Neon_Blaster",
    name: "Neon Blaster",
    icon: "game_pic/icon/neon-blaster-130.webp",
    logo: "game_pic/icon2/neon-blaster-130.webp",
    image: "game_pic/icon3/neon-blaster-130.webp",
    icon4: "game_pic/icon4/neon-blaster-130.webp",
    icon5:
      "game_pic/icon_large/neon-blaster-130.webp",
    icon6: "",
    playCount: "243266",
    rating: "5.0",
    description:
      "Neon Blaster is an action-packed game where your objective is to eliminate numbered balls before they descend to the bottom of the screen. Each ball is assigned a number, indicating how many hits it takes to destroy it. As the game progresses, the numbers on the balls become higher, increasing the challenge. Your task is to aim and shoot accurately to clear the screen of these balls while avoiding their descent. Neon Blaster offers fast-paced gameplay and increasing difficulty levels, making it a thrilling and addictive arcade experience.",
    createdTime: "",
    url: "games/NeonBlaster/",
    icon2Large:
      "game_pic/icon2_large/neon-blaster-130.webp",
    icon3Large:
      "game_pic/icon3_large/neon-blaster-130.webp",
    categoryName: "",
    categoryId: ",64,72,60,70,",
    intro:
      "In Neon Blaster, your mission is to destroy numbered balls before they reach the bottom of the screen, with each ball requiring a specific number of hits to be destroyed.",
    gamePlay:
      "Destroy numbered balls by shooting them before they reach the bottom of the screen, with each ball requiring a specific number of hits to be eliminated.",
    likeRatio: "88",
    likeCount: "49237",
    dislikeCount: "6714",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/neon-blaster-130.webp",
  },
  {
    id: "131",
    path: "/games/morelist/131_Fruit_Bubble_Shooters",
    name: "Fruit Bubble Shooters",
    icon: "game_pic/icon/fruit-bubble-shooters-131.webp",
    logo: "game_pic/icon2/fruit-bubble-shooters-131.webp",
    image:
      "game_pic/icon3/fruit-bubble-shooters-131.webp",
    icon4:
      "game_pic/icon4/fruit-bubble-shooters-131.webp",
    icon5:
      "game_pic/icon_large/fruit-bubble-shooters-131.webp",
    icon6:
      "game_pic/icon6/fruit-bubble-shooters-131.webp",
    playCount: "426701",
    rating: "5.0",
    description:
      "Fruit Bubble Shooters offers classic bubble shooter mechanics, allowing players to control a shooter at the bottom of the screen. Your task is to launch bubbles of different colors into the playing field filled with bubbles of varying colors. The objective remains the same: match three or more bubbles of the same color to make them pop. With engaging levels and challenging puzzles, Fruit Bubble Shooters delivers a timeless and addictive bubble-popping experience for players of all ages.",
    createdTime: "",
    url: "games/Fruitbubbleshooters/",
    icon2Large:
      "game_pic/icon2_large/fruit-bubble-shooters-131.webp",
    icon3Large:
      "game_pic/icon3_large/fruit-bubble-shooters-131.webp",
    categoryName: "",
    categoryId: ",64,70,85,",
    intro:
      "Delve into the familiar gameplay of bubble shooting in Fruit Bubble Shooters, where players control a shooter at the bottom of the screen to pop bubbles by matching colors.",
    gamePlay:
      "Control the shooter at the bottom of the screen to launch bubbles, aiming to match three or more bubbles of the same color and pop them.",
    likeRatio: "70",
    likeCount: "65711",
    dislikeCount: "28163",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/fruit-bubble-shooters-131.webp",
  },
  {
    id: "132",
    path: "/games/morelist/132_Dance_Race",
    name: "Dance Race",
    icon: "game_pic/icon/dance-race-132.webp",
    logo: "game_pic/icon2/dance-race-132.webp",
    image: "game_pic/icon3/dance-race-132.webp",
    icon4: "game_pic/icon4/dance-race-132.webp",
    icon5:
      "game_pic/icon_large/dance-race-132.webp",
    icon6: "",
    playCount: "679837",
    rating: "5.0",
    description:
      "Dance Race offers a unique and challenging gameplay experience where you control a pole dancing girl. Click to change her dance moves and maneuver through obstacles of specific shapes. You'll need to adjust the girl's height on the pole, moving her up and down, to ensure she passes obstacles seamlessly. Dance Race combines precision, timing, and rhythm to create an engaging and entertaining dance-themed game for players who enjoy a different kind of challenge.",
    createdTime: "",
    url: "games/DanceRace/",
    icon2Large:
      "game_pic/icon2_large/dance-race-132.webp",
    icon3Large:
      "game_pic/icon3_large/dance-race-132.webp",
    categoryName: "",
    categoryId: ",7,8,",
    intro:
      "In Dance Race, you interact with a pole dancing girl by clicking to change her dance moves, navigate through shape-based obstacles, and adjust her height on the pole to progress smoothly.",
    gamePlay:
      "Click to change the pole dancing girl's dance moves, pass through shape-based obstacles, and adjust her height on the pole to ensure a smooth progression.",
    likeRatio: "75",
    likeCount: "214149",
    dislikeCount: "71383",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/dance-race-132.webp",
  },
  {
    id: "134",
    path: "/games/morelist/134_Run_into_Death",
    name: "Run into Death",
    icon: "game_pic/icon/run-into-death-134.webp",
    logo: "game_pic/icon2/run-into-death-134.webp",
    image:
      "game_pic/icon3/run-into-death-134.webp",
    icon4: "",
    icon5:
      "game_pic/icon_large/run-into-death-134.webp",
    icon6: "",
    playCount: "72448",
    rating: "5.0",
    description:
      "Run into Death immerses players in a terrifying game environment where they must defend against approaching horrifying creatures. Armed with a pistol, your objective is to eliminate these creatures as they draw near. The game's style is designed to be scary and intense, creating a chilling atmosphere that keeps you on edge. Run into Death is a horror-themed game that challenges your aim and reflexes as you strive to survive against nightmarish foes.",
    createdTime: "",
    url: "games/runintodeath/",
    icon2Large:
      "game_pic/icon2_large/run-into-death-134.webp",
    icon3Large:
      "game_pic/icon3_large/run-into-death-134.webp",
    categoryName: "",
    categoryId: ",60,59,",
    intro:
      "Face terrifying creatures in a scary game style as you pick up a pistol and keep eliminating the approaching horrors in Run into Death.",
    gamePlay:
      "Pick up a pistol and keep shooting at terrifying creatures as they approach in this scary game style.",
    likeRatio: "70",
    likeCount: "10650",
    dislikeCount: "4565",
    displayMethod: "",
    icon4Large: "",
  },
  {
    id: "135",
    path: "/games/morelist/135_Traffic_Go",
    name: "Traffic Go",
    icon: "game_pic/icon/traffic-go-135.webp",
    logo: "game_pic/icon2/traffic-go-135.webp",
    image: "game_pic/icon3/traffic-go-135.webp",
    icon4: "game_pic/icon4/traffic-go-135.webp",
    icon5:
      "game_pic/icon_large/traffic-go-135.webp",
    icon6: "",
    playCount: "47574",
    rating: "5.0",
    description:
      "Traffic Go offers a thrilling driving experience in a bustling city. Your task is to press forward and guide cars through the criss-crossing roads, avoiding collisions and ensuring smooth traffic flow. The game challenges your reflexes and strategic thinking as you navigate through a complex road network. Traffic Go provides an exciting and fast-paced driving adventure, perfect for players who enjoy testing their traffic management skills.",
    createdTime: "",
    url: "games/Traffic_Go/",
    icon2Large:
      "game_pic/icon2_large/traffic-go-135.webp",
    icon3Large:
      "game_pic/icon3_large/traffic-go-135.webp",
    categoryName: "",
    categoryId: ",6,65,",
    intro:
      "Test your driving skills in Traffic Go as you press forward to navigate cars through a bustling city with criss-crossing roads.",
    gamePlay:
      "Press forward to control cars, ensuring they navigate through a city with criss-crossing roads without collisions.",
    likeRatio: "90",
    likeCount: "9420",
    dislikeCount: "1047",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/traffic-go-135.webp",
  },
  {
    id: "136",
    path: "/games/morelist/136_Wordie",
    name: "Wordie",
    icon: "game_pic/icon/wordie-136.webp",
    logo: "game_pic/icon2/wordie-136.webp",
    image: "game_pic/icon3/wordie-136.webp",
    icon4: "game_pic/icon4/wordie-136.webp",
    icon5: "game_pic/icon_large/wordie-136.webp",
    icon6: "",
    playCount: "98590",
    rating: "5.0",
    description:
      "Wordie challenges players to rearrange letters to form complete words, following provided prompts. The game offers a variety of word puzzles that test your vocabulary and word-solving abilities. As you progress, the complexity of the words and the rearrangement challenges increase, providing an enjoyable word-based puzzle experience. Wordie is perfect for players who love word games and enjoy deciphering anagrams and word jumbles.",
    createdTime: "",
    url: "games/Wordie/",
    icon2Large:
      "game_pic/icon2_large/wordie-136.webp",
    icon3Large:
      "game_pic/icon3_large/wordie-136.webp",
    categoryName: "",
    categoryId: ",3,61,",
    intro:
      "Engage your word skills in Wordie as you follow prompts to rearrange letters and complete words.",
    gamePlay:
      "Follow prompts to rearrange letters and complete words in a series of word puzzles.",
    likeRatio: "80",
    likeCount: "24450",
    dislikeCount: "6112",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/wordie-136.webp",
  },
  {
    id: "137",
    path: "/games/morelist/137_Cannon_Balls",
    name: "Cannon Balls",
    icon: "game_pic/icon/cannon-balls-137.webp",
    logo: "game_pic/icon2/cannon-balls-137.webp",
    image: "game_pic/icon3/cannon-balls-137.webp",
    icon4: "game_pic/icon4/cannon-balls-137.webp",
    icon5:
      "game_pic/icon_large/cannon-balls-137.webp",
    icon6: "",
    playCount: "740601",
    rating: "5.0",
    description:
      "Cannon Balls is reminiscent of the classic game Bubble Bobble, where you fire cannonballs at numbered balls, each indicating the number of hits required to destroy it. The challenge comes with obstacles that appear to hinder your cannonball shots. Your goal is to seize opportunities, aim carefully, and time your shots to hit the balls while avoiding obstacles. Cannon Balls provides a thrilling and strategic gameplay experience for fans of classic bubble-shooting games.",
    createdTime: "",
    url: "games/CannonBalls/",
    icon2Large:
      "game_pic/icon2_large/cannon-balls-137.webp",
    icon3Large:
      "game_pic/icon3_large/cannon-balls-137.webp",
    categoryName: "",
    categoryId: ",4,70,64,79,65,",
    intro:
      "Enter the world of Bubble Bobble in Cannon Balls, where you fire cannonballs to eliminate numbered balls with the goal of hitting them the required number of times while avoiding obstacles.",
    gamePlay:
      "Fire cannonballs to eliminate numbered balls, aiming to hit them the required number of times while avoiding obstacles.",
    likeRatio: "88",
    likeCount: "221588",
    dislikeCount: "30217",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/cannon-balls-137.webp",
  },
  {
    id: "138",
    path: "/games/morelist/138_Guns_Bottles",
    name: "Guns Bottles",
    icon: "game_pic/icon/guns-bottles-138.webp",
    logo: "game_pic/icon2/guns-bottles-138.webp",
    image: "game_pic/icon3/guns-bottles-138.webp",
    icon4: "game_pic/icon4/guns-bottles-138.webp",
    icon5:
      "game_pic/icon_large/guns-bottles-138.webp",
    icon6: "",
    playCount: "379603",
    rating: "5.0",
    description:
      "Guns Bottles presents a challenge where bottles rotate around a gun, and players must use the same 360-degree rotating gun to break the bottles. However, bullets are limited, requiring precision and accuracy in shooting. Managing your shots becomes crucial as you aim to break all the bottles within the available ammunition. Guns Bottles offers an engaging target-shooting experience that demands both skill and strategy.",
    createdTime: "",
    url: "games/GunsBottles/",
    icon2Large:
      "game_pic/icon2_large/guns-bottles-138.webp",
    icon3Large:
      "game_pic/icon3_large/guns-bottles-138.webp",
    categoryName: "",
    categoryId: ",60,65,",
    intro:
      "Test your aiming and shooting skills in Guns Bottles as you use a rotating gun to break bottles that rotate around it, while managing limited bullets.",
    gamePlay:
      "Use a rotating gun to break bottles that rotate around it while managing limited bullets.",
    likeRatio: "89",
    likeCount: "125003",
    dislikeCount: "15450",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/guns-bottles-138.webp",
  },
  {
    id: "139",
    path: "/games/morelist/139_Shot_Trigger",
    name: "Shot Trigger",
    icon: "game_pic/icon/shot-trigger-139.webp",
    logo: "game_pic/icon2/shot-trigger-139.webp",
    image: "game_pic/icon3/shot-trigger-139.webp",
    icon4: "game_pic/icon4/shot-trigger-139.webp",
    icon5:
      "game_pic/icon_large/shot-trigger-139.webp",
    icon6: "",
    playCount: "92975",
    rating: "5.0",
    description:
      "Our hero will rescue the civilians from the underworld. Can you help him?",
    createdTime: "",
    url: "games/Shot_Trigger/",
    icon2Large:
      "game_pic/icon2_large/shot-trigger-139.webp",
    icon3Large:
      "game_pic/icon3_large/shot-trigger-139.webp",
    categoryName: "",
    categoryId: ",60,",
    intro:
      "Our hero will rescue the civilians from the underworld. Can you help him?",
    gamePlay: "click to play",
    likeRatio: "81",
    likeCount: "13555",
    dislikeCount: "3180",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/shot-trigger-139.webp",
  },
  {
    id: "141",
    path: "/games/morelist/141_Wiggle",
    name: "Wiggle",
    icon: "game_pic/icon/wiggle-141.webp",
    logo: "game_pic/icon2/wiggle-141.webp",
    image: "game_pic/icon3/wiggle-141.webp",
    icon4: "game_pic/icon4/wiggle-141.webp",
    icon5: "game_pic/icon_large/wiggle-141.webp",
    icon6: "",
    playCount: "46213",
    rating: "5.0",
    description:
      "Wiggle features a charming and adorable worm protagonist on a daring escape from a flood. Your task is to navigate the worm through a challenging environment, avoiding walls, holes, and blue balls that slow you down. Collect pink dots to regain stamina and rainbow dots for an even bigger stamina boost. Special items like shields, soccer balls, ramps, coins, magnets, and boosts add variety and excitement to the gameplay. Wiggle is a skill-based game that challenges you to set new high scores with each attempt.",
    createdTime: "",
    url: "games/Wiggle/",
    icon2Large:
      "game_pic/icon2_large/wiggle-141.webp",
    icon3Large:
      "game_pic/icon3_large/wiggle-141.webp",
    categoryName: "",
    categoryId: ",82,68,65,",
    intro:
      "Guide a cute little worm as it flees from a flood in Wiggle, a skill-based game where you aim to wiggle as far as possible, avoiding obstacles and collecting items to earn a high score.",
    gamePlay:
      "Guide the worm to wiggle as far as possible, avoiding obstacles, collecting dots for stamina, and using items strategically to earn a high score.",
    likeRatio: "85",
    likeCount: "13356",
    dislikeCount: "2357",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/wiggle-141.webp",
  },
  {
    id: "142",
    path: "/games/morelist/142_Tank_Hero_Online",
    name: "Tank Hero Online",
    icon: "game_pic/icon/tank-hero-online-142.webp",
    logo: "game_pic/icon2/tank-hero-online-142.webp",
    image:
      "game_pic/icon3/tank-hero-online-142.webp",
    icon4:
      "game_pic/icon4/tank-hero-online-142.webp",
    icon5:
      "game_pic/icon_large/tank-hero-online-142.webp",
    icon6: "",
    playCount: "660610",
    rating: "5.0",
    description:
      "Tank Hero Online offers thrilling tank battles with strategic elements. Choose between normal and hard modes and engage in intense tank warfare. The game features preset parabola shots that help you hit your opponents' tanks with precision. Your goal is to strategize your shots and outmaneuver your enemies to secure victory. Tank Hero Online delivers exciting tank combat and challenges your tactical skills in a dynamic battlefield.",
    createdTime: "",
    url: "games/TankHeroOnline/",
    icon2Large:
      "game_pic/icon2_large/tank-hero-online-142.webp",
    icon3Large:
      "game_pic/icon3_large/tank-hero-online-142.webp",
    categoryName: "",
    categoryId: ",60,66,80,",
    intro:
      "Enter the battlefield and engage in strategic tank battles in Tank Hero Online, featuring two modes: normal and hard, with preset parabola shots to hit opponents' tanks.",
    gamePlay: "TOUCH TO RIM_\nRELEASE TO SHOT",
    likeRatio: "85",
    likeCount: "145995",
    dislikeCount: "25764",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/tank-hero-online-142.webp",
  },
  {
    id: "143",
    path: "/games/morelist/143_Subway_Endless_Run",
    name: "Subway Endless Run",
    icon: "game_pic/icon/subway-endless-run-143.webp",
    logo: "game_pic/icon2/subway-endless-run-143.webp",
    image:
      "game_pic/icon3/subway-endless-run-143.webp",
    icon4:
      "game_pic/icon4/subway-endless-run-143.webp",
    icon5:
      "game_pic/icon_large/subway-endless-run-143.webp",
    icon6:
      "game_pic/icon6/subway-endless-run-143.webp",
    playCount: "685301",
    rating: "5.0",
    description:
      "Subway Endless Run combines the excitement of endless running with cute characters and a subway city setting. Your mission is to save a beautiful princess while running through the subway cityscape. Avoid obstacles, manipulate props, and stay ahead of pursuing cats. The game offers fast-paced and dynamic gameplay, challenging your reflexes as you strive to outrun your pursuers. Subway Endless Run provides a thrilling running adventure inspired by popular titles.",
    createdTime: "",
    url: "games/subwayendlessrun/",
    icon2Large:
      "game_pic/icon2_large/subway-endless-run-143.webp",
    icon3Large:
      "game_pic/icon3_large/subway-endless-run-143.webp",
    categoryName: "",
    categoryId: ",7,8,68,",
    intro:
      "Experience a new running adventure with cute girls in Subway Endless Run, a game inspired by titles like Temple Run and Subway Surfers, as you save your beloved princess and run through a subway city.",
    gamePlay:
      "Run through the subway city, avoiding obstacles and manipulating props to evade chasing cats and save the princess.",
    likeRatio: "87",
    likeCount: "315992",
    dislikeCount: "47217",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/subway-endless-run-143.webp",
  },
  {
    id: "243",
    path: "/games/morelist/243_Amaze",
    name: "Amaze",
    icon: "game_pic/icon/amaze-243.webp",
    logo: "game_pic/icon2/amaze-243.webp",
    image: "game_pic/icon3/amaze-243.webp",
    icon4: "game_pic/icon4/amaze-243.webp",
    icon5: "game_pic/icon_large/amaze-243.webp",
    icon6: "",
    playCount: "731583",
    rating: "5.0",
    description:
      "Amaze challenges players to paint walls with the least amount of paint possible. The objective is to plan a route through maze-like puzzles, ensuring that every wall section is painted while using the least paint strokes. As you progress, the puzzles become more intricate, demanding careful thought and strategic planning to conserve paint. Amaze is a puzzle game that tests your problem-solving skills as you aim to complete each level with the utmost efficiency.",
    createdTime: "",
    url: "games/Amaze/",
    icon2Large:
      "game_pic/icon2_large/amaze-243.webp",
    icon3Large:
      "game_pic/icon3_large/amaze-243.webp",
    categoryName: "",
    categoryId: ",61,3,76,",
    intro:
      "Plan your path strategically to paint walls with minimal paint in Amaze, a puzzle game where efficient route planning is key.",
    gamePlay:
      "Plan your path to paint walls while using as little paint as possible in maze-like puzzles.",
    likeRatio: "90",
    likeCount: "204112",
    dislikeCount: "22679",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/amaze-243.webp",
  },
  {
    id: "244",
    path: "/games/morelist/244_Amazing_Cook",
    name: "Amazing Cook",
    icon: "game_pic/icon/amazing-cook-244.webp",
    logo: "game_pic/icon2/amazing-cook-244.webp",
    image: "game_pic/icon3/amazing-cook-244.webp",
    icon4: "game_pic/icon4/amazing-cook-244.webp",
    icon5:
      "game_pic/icon_large/amazing-cook-244.webp",
    icon6: "",
    playCount: "676939",
    rating: "5.0",
    description:
      "Amazing Cook is a fast-paced game where you must gather ingredients for your restaurant by swiping quickly on the screen. However, you can also cut other players' ingredients to gain an advantage. The challenge lies in balancing your offensive and defensive strategies to maximize your ingredient collection while protecting your own. With a competitive and dynamic gameplay style, Amazing Cook offers a unique and engaging experience for players who enjoy fast reflexes and strategic decision-making.",
    createdTime: "",
    url: "games/amazingcook/",
    icon2Large:
      "game_pic/icon2_large/amazing-cook-244.webp",
    icon3Large:
      "game_pic/icon3_large/amazing-cook-244.webp",
    categoryName: "",
    categoryId: ",61,65,85,",
    intro:
      "Swipe quickly on the screen in Amazing Cook to gather as many ingredients as possible for your restaurant while strategically cutting other players' ingredients without losing your own.",
    gamePlay:
      "Swipe to collect ingredients for your restaurant while strategically cutting other players' ingredients to gain an edge.",
    likeRatio: "84",
    likeCount: "90981",
    dislikeCount: "17330",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/amazing-cook-244.webp",
  },
  {
    id: "245",
    path: "/games/morelist/245_Among_Us_Crazy_Shooter",
    name: "Among Us Crazy Shooter",
    icon: "game_pic/icon/among-us-crazy-shooter-245.webp",
    logo: "game_pic/icon2/among-us-crazy-shooter-245.webp",
    image:
      "game_pic/icon3/among-us-crazy-shooter-245.webp",
    icon4:
      "game_pic/icon4/among-us-crazy-shooter-245.webp",
    icon5:
      "game_pic/icon_large/among-us-crazy-shooter-245.webp",
    icon6:
      "game_pic/icon6/among-us-crazy-shooter-245.webp",
    playCount: "591915",
    rating: "5.0",
    description:
      "Among Us Crazy Shooter immerses you in the world of imposters, where you must navigate buildings, dodge enemy bullets, and eliminate adversaries. The game offers a challenging online shooter experience with an Among Us theme. Your objective is to clear each level by defeating all enemies while avoiding their shots. With 2 challenging levels and a variety of guns to unlock, Among Us Crazy Shooter provides an exciting and action-packed gaming experience.",
    createdTime: "",
    url: "games/Among_Us_Crazy_Shooter/",
    icon2Large:
      "game_pic/icon2_large/among-us-crazy-shooter-245.webp",
    icon3Large:
      "game_pic/icon3_large/among-us-crazy-shooter-245.webp",
    categoryName: "",
    categoryId: ",60,7,75,",
    intro:
      "Join the action-packed world of Among Us Crazy Shooter, where you must move swiftly, avoid enemy bullets, and eliminate imposters in this thrilling online shooter game.",
    gamePlay:
      "Move swiftly to avoid enemy bullets and shoot down imposters to complete levels in this online Among Us-themed shooter game.",
    likeRatio: "73",
    likeCount: "229011",
    dislikeCount: "84703",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/among-us-crazy-shooter-245.webp",
  },
  {
    id: "246",
    path: "/games/morelist/246_Archer_Warrior",
    name: "Archer Warrior",
    icon: "game_pic/icon/archer-warrior-246.webp",
    logo: "game_pic/icon2/archer-warrior-246.webp",
    image:
      "game_pic/icon3/archer-warrior-246.webp",
    icon4:
      "game_pic/icon4/archer-warrior-246.webp",
    icon5:
      "game_pic/icon_large/archer-warrior-246.webp",
    icon6: "",
    playCount: "499867",
    rating: "5.0",
    description:
      "Archer Warrior puts you in control of a rubber man on horseback, dashing forward and engaging enemies with bows and arrows. Your task is to navigate through various levels, shooting down foes and completing specified tasks. The game challenges your aiming skills and precision as you ride forward. Enjoy the Archer Warrior's casual shooting action as you take on enemies and progress through the levels.",
    createdTime: "",
    url: "games/Archer_Warrior/",
    icon2Large:
      "game_pic/icon2_large/archer-warrior-246.webp",
    icon3Large:
      "game_pic/icon3_large/archer-warrior-246.webp",
    categoryName: "",
    categoryId: ",60,78,82,83,",
    intro:
      "Take on the role of an Archer Warrior riding on horseback, rushing forward, and using bows and arrows to defeat enemies in this casual shooting game.",
    gamePlay:
      "Control the left and right movements of the Archer Warrior, shoot enemies with bows and arrows, and complete level-specific tasks.",
    likeRatio: "84",
    likeCount: "155359",
    dislikeCount: "29592",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/archer-warrior-246.webp",
  },
  {
    id: "247",
    path: "/games/morelist/247_Axe_Master",
    name: "Axe Master",
    icon: "game_pic/icon/axe-master-247.webp",
    logo: "game_pic/icon2/axe-master-247.webp",
    image: "game_pic/icon3/axe-master-247.webp",
    icon4: "game_pic/icon4/axe-master-247.webp",
    icon5:
      "game_pic/icon_large/axe-master-247.webp",
    icon6: "game_pic/icon6/axe-master-247.webp",
    playCount: "587463",
    rating: "5.0",
    description:
      "Axe Master offers a simple and addictive gaming experience where you throw axes by tapping the screen. Your goal is to hit your target with precision for the highest score. To increase your hit rate, you need to anticipate the axe's trajectory and timing. Axe Master challenges your accuracy and aiming skills as you attempt to achieve the best possible throws.",
    createdTime: "",
    url: "games/Axe_Master/",
    icon2Large:
      "game_pic/icon2_large/axe-master-247.webp",
    icon3Large:
      "game_pic/icon3_large/axe-master-247.webp",
    categoryName: "",
    categoryId: ",4,65,",
    intro:
      "Axe Master is a straightforward click-and-smash casual game where you tap the screen to throw your axe and aim for precision hits.",
    gamePlay:
      "Tap the screen to throw your axe and aim for precision hits, trying to hit the target you aim at.",
    likeRatio: "83",
    likeCount: "112146",
    dislikeCount: "22970",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/axe-master-247.webp",
  },
  {
    id: "249",
    path: "/games/morelist/249_Boat_Hitting_Out",
    name: "Boat Hitting Out",
    icon: "game_pic/icon/boat-hitting-out-249.webp",
    logo: "game_pic/icon2/boat-hitting-out-249.webp",
    image:
      "game_pic/icon3/boat-hitting-out-249.webp",
    icon4:
      "game_pic/icon4/boat-hitting-out-249.webp",
    icon5:
      "game_pic/icon_large/boat-hitting-out-249.webp",
    icon6: "",
    playCount: "779089",
    rating: "5.0",
    description:
      "Boat Hitting Out offers a competitive gameplay experience where you control ships to collect resources while engaging in battles with rival players. Your goal is to continuously defeat the same rivals to plunder resources and ultimately secure victory. This game combines strategy and action as you strategize your ship movements and engage in fierce battles. Boat Hitting Out provides an intense and dynamic competition for players looking for a challenging multiplayer experience.",
    createdTime: "",
    url: "games/Boat_Hitting_Out/",
    icon2Large:
      "game_pic/icon2_large/boat-hitting-out-249.webp",
    icon3Large:
      "game_pic/icon3_large/boat-hitting-out-249.webp",
    categoryName: "",
    categoryId: ",74,78,66,",
    intro:
      "Take control of ships, collect resources, and engage in constant battles with rivals to plunder resources and become the ultimate winner in Boat Hitting Out.",
    gamePlay:
      "Control ships, collect resources, and engage in battles with rival players to plunder resources and aim for victory.",
    likeRatio: "82",
    likeCount: "153325",
    dislikeCount: "33657",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/boat-hitting-out-249.webp",
  },
  {
    id: "250",
    path: "/games/morelist/250_Color_Roll_3D",
    name: "Color Roll 3D",
    icon: "game_pic/icon/color-roll-3d-250.webp",
    logo: "game_pic/icon2/color-roll-3d-250.webp",
    image: "game_pic/icon3/color-roll-3d-250.webp",
    icon4: "game_pic/icon4/color-roll-3d-250.webp",
    icon5:
      "game_pic/icon_large/color-roll-3d-250.webp",
    icon6: "",
    playCount: "735705",
    rating: "5.0",
    description:
      "Color Roll 3D 2 is a puzzle game that challenges your observation and puzzle-solving skills. Your objective is to unfold the color roll in the correct order to match the given picture. Pay attention to the order of colors, as you need to determine which one is on top and which one is on the bottom to complete each level successfully. With engaging and colorful puzzles, Color Roll 3D 2 provides a fun and mentally stimulating experience.",
    createdTime: "",
    url: "games/Color_Roll_3D/",
    icon2Large:
      "game_pic/icon2_large/color-roll-3d-250.webp",
    icon3Large:
      "game_pic/icon3_large/color-roll-3d-250.webp",
    categoryName: "",
    categoryId: ",61,84,4,",
    intro:
      "Solve colorful puzzles by unfolding the color roll in the correct order to match the given picture in Color Roll 3D 2.",
    gamePlay:
      "Unfold the color roll in the correct order to match the given picture in colorful and challenging puzzles.",
    likeRatio: "84",
    likeCount: "160678",
    dislikeCount: "30606",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/color-roll-3d-250.webp",
  },
  {
    id: "251",
    path: "/games/morelist/251_Drive_Hills",
    name: "Drive Hills",
    icon: "game_pic/icon/drive-hills-251.webp",
    logo: "game_pic/icon2/drive-hills-251.webp",
    image: "game_pic/icon3/drive-hills-251.webp",
    icon4: "game_pic/icon4/drive-hills-251.webp",
    icon5:
      "game_pic/icon_large/drive-hills-251.webp",
    icon6: "",
    playCount: "579940",
    rating: "5.0",
    description:
      "Drive Hills offers an exciting 3D car driving experience where you must travel the world while carrying an egg. However, the road is filled with obstacles and difficulties that you must overcome to reach the next city. Collect coins along the way to unlock new cars and eggs, adding variety to your journeys. With stunning 3D graphics, Drive Hills combines driving skills and strategy as you navigate challenging terrain. Don't miss the chance to explore this adventurous world!",
    createdTime: "",
    url: "games/Drive_Hills/",
    icon2Large:
      "game_pic/icon2_large/drive-hills-251.webp",
    icon3Large:
      "game_pic/icon3_large/drive-hills-251.webp",
    categoryName: "",
    categoryId: ",6,75,",
    intro:
      "Embark on an excellent 3D car driving adventure in Drive Hills, where you must drive your car while carrying an egg and overcome obstacles to unlock new cars and eggs.",
    gamePlay:
      "Drive your car while carrying an egg, collect coins to unlock new cars and eggs, and overcome obstacles to progress through different cities.",
    likeRatio: "80",
    likeCount: "245895",
    dislikeCount: "61474",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/drive-hills-251.webp",
  },
  {
    id: "252",
    path: "/games/morelist/252_Emoji_game",
    name: "Emoji game",
    icon: "game_pic/icon/emoji-game-252.webp",
    logo: "game_pic/icon2/emoji-game-252.webp",
    image: "game_pic/icon3/emoji-game-252.webp",
    icon4: "game_pic/icon4/emoji-game-252.webp",
    icon5:
      "game_pic/icon_large/emoji-game-252.webp",
    icon6: "",
    playCount: "375540",
    rating: "5.0",
    description:
      "Emoji Game is a unique imagination game where you must connect pairs of emotions by finding associations between them. To solve each puzzle, tap on elements from different columns to connect them with a line or drag to draw a line between associated elements. The challenge lies in thinking creatively to find the right connections. The game becomes progressively harder as you advance, offering an enjoyable mental workout that's sure to test your creativity and lateral thinking.",
    createdTime: "",
    url: "games/Emoji_game/",
    icon2Large:
      "game_pic/icon2_large/emoji-game-252.webp",
    icon3Large:
      "game_pic/icon3_large/emoji-game-252.webp",
    categoryName: "",
    categoryId: ",61,75,64,",
    intro:
      "Exercise your imagination in Emoji Game as you connect pairs of emotions through associations, testing your puzzle-solving skills.",
    gamePlay:
      "Connect pairs of emotions by drawing lines or tapping elements that are associated with each other to pass levels.",
    likeRatio: "85",
    likeCount: "102147",
    dislikeCount: "18026",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/emoji-game-252.webp",
  },
  {
    id: "253",
    path: "/games/morelist/253_Evil_Santa",
    name: "Evil Santa",
    icon: "game_pic/icon/evil-santa-253.webp",
    logo: "game_pic/icon2/evil-santa-253.webp",
    image: "game_pic/icon3/evil-santa-253.webp",
    icon4: "game_pic/icon4/evil-santa-253.webp",
    icon5:
      "game_pic/icon_large/evil-santa-253.webp",
    icon6: "",
    playCount: "448651",
    rating: "5.0",
    description:
      "Evil Santa presents a unique chapter-based game where your goal is to escape from the clutches of the Scary Santa. To win, you must find hidden cards and use magic candies to reveal the face of the sinister Santa. External surveillance cameras can also aid your escape. The game offers an eerie and suspenseful atmosphere, with each chapter presenting new challenges and puzzles to solve. Evil Santa delivers an engaging and mysterious gaming experience as you strive to outwit the malevolent Santa.",
    createdTime: "",
    url: "games/Evil_Santa/",
    icon2Large:
      "game_pic/icon2_large/evil-santa-253.webp",
    icon3Large:
      "game_pic/icon3_large/evil-santa-253.webp",
    categoryName: "",
    categoryId: ",60,3,",
    intro:
      "Uncover hidden cards and use magic candies to outsmart the Scary Santa in Evil Santa, a chapter-based game of escape and intrigue.",
    gamePlay:
      "Explore each chapter, find hidden cards, use magic candies, and employ surveillance cameras to outsmart the Scary Santa and win the game.",
    likeRatio: "85",
    likeCount: "137287",
    dislikeCount: "24227",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/evil-santa-253.webp",
  },
  {
    id: "254",
    path: "/games/morelist/254_Fruit_Slice",
    name: "Fruit Slice",
    icon: "game_pic/icon/fruit-slice-254.webp",
    logo: "game_pic/icon2/fruit-slice-254.webp",
    image: "game_pic/icon3/fruit-slice-254.webp",
    icon4: "game_pic/icon4/fruit-slice-254.webp",
    icon5:
      "game_pic/icon_large/fruit-slice-254.webp",
    icon6: "",
    playCount: "41923",
    rating: "5.0",
    description:
      "Fruit Slice offers a delightful gameplay experience where you prepare and serve various flavors of delicious fruit juices. Your objective is to follow instructions to create these refreshing beverages by slicing fruits with precision. The game provides colorful graphics and engaging mechanics that involve slicing fruits in different ways to meet customer demands. Fruit Slice Games deliver a fun and casual experience that will challenge your slicing skills and creativity.",
    createdTime: "",
    url: "games/Fruit_Slice/",
    icon2Large:
      "game_pic/icon2_large/fruit-slice-254.webp",
    icon3Large:
      "game_pic/icon3_large/fruit-slice-254.webp",
    categoryName: "",
    categoryId: ",61,4,85,",
    intro:
      "Follow instructions to create delicious and unique fruit juices by slicing fruits and serving your customers in the colorful world of Fruit Slice Games.",
    gamePlay:
      "Slice fruits according to instructions to create unique fruit juices and serve them to customers.",
    likeRatio: "86",
    likeCount: "10456",
    dislikeCount: "1702",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/fruit-slice-254.webp",
  },
  {
    id: "255",
    path: "/games/morelist/255_Nerf_Epic_Pranks",
    name: "Nerf Epic Pranks",
    icon: "game_pic/icon/nerf-epic-pranks-255.webp",
    logo: "game_pic/icon2/nerf-epic-pranks-255.webp",
    image:
      "game_pic/icon3/nerf-epic-pranks-255.webp",
    icon4:
      "game_pic/icon4/nerf-epic-pranks-255.webp",
    icon5:
      "game_pic/icon_large/nerf-epic-pranks-255.webp",
    icon6:
      "game_pic/icon6/nerf-epic-pranks-255.webp",
    playCount: "105528",
    rating: "5.0",
    description:
      "Nerf Epic Pranks lets you embark on a hilarious adventure of pranks, where you use Nerf blasters to target unsuspecting people. The game's objective is to prank them by holding your finger on the screen to fire the blasters. You'll encounter two types of people in the game�those with blasters and those without. The challenge is to time your pranks right and prank as many people as possible without getting caught. Nerf Epic Pranks offers a lighthearted and mischievous gaming experience that's sure to keep you entertained.",
    createdTime: "",
    url: "games/Nerf_Epic_Pranks/",
    icon2Large:
      "game_pic/icon2_large/nerf-epic-pranks-255.webp",
    icon3Large:
      "game_pic/icon3_large/nerf-epic-pranks-255.webp",
    categoryName: "",
    categoryId: ",60,7,75,",
    intro:
      "Engage in epic Nerf pranks as you fire Nerf blasters at unsuspecting targets, both with and without blasters, in this playful and mischievous game.",
    gamePlay:
      "Hold your finger on the screen to fire Nerf blasters at unsuspecting targets, both with and without blasters, to prank them without getting caught.",
    likeRatio: "81",
    likeCount: "45303",
    dislikeCount: "10626",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/nerf-epic-pranks-255.webp",
  },
  {
    id: "256",
    path: "/games/morelist/256_Pen_Run_Online",
    name: "Pen Run Online",
    icon: "game_pic/icon/pen-run-online-256.webp",
    logo: "game_pic/icon2/pen-run-online-256.webp",
    image:
      "game_pic/icon3/pen-run-online-256.webp",
    icon4:
      "game_pic/icon4/pen-run-online-256.webp",
    icon5:
      "game_pic/icon_large/pen-run-online-256.webp",
    icon6: "",
    playCount: "34971",
    rating: "5.0",
    description:
      "Pen Run Online is a captivating arcade game where you control a running pen on a table. Your goal is to collect additional pens while avoiding obstacles such as books and pen containers. Slide your way to the end of the table, and the extra pens you gather will be added to your collection. The game offers a simple yet challenging gameplay experience that tests your reflexes and precision.",
    createdTime: "",
    url: "games/Pen_Run_Online/",
    icon2Large:
      "game_pic/icon2_large/pen-run-online-256.webp",
    icon3Large:
      "game_pic/icon3_large/pen-run-online-256.webp",
    categoryName: "",
    categoryId: ",61,68,69,",
    intro:
      "Experience the thrill of a pen running adventure in Pen Run Online as you slide to navigate the pen on a table, collecting other pens while avoiding obstacles.",
    gamePlay:
      "Slide to control the running pen, collecting other pens and avoiding obstacles to reach the end of the table.",
    likeRatio: "83",
    likeCount: "10740",
    dislikeCount: "2200",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/pen-run-online-256.webp",
  },
  {
    id: "257",
    path: "/games/morelist/257_Pole_Vault_Jump",
    name: "Pole Vault Jump",
    icon: "game_pic/icon/pole-vault-jump-257.webp",
    logo: "game_pic/icon2/pole-vault-jump-257.webp",
    image:
      "game_pic/icon3/pole-vault-jump-257.webp",
    icon4:
      "game_pic/icon4/pole-vault-jump-257.webp",
    icon5:
      "game_pic/icon_large/pole-vault-jump-257.webp",
    icon6: "",
    playCount: "84964",
    rating: "5.0",
    description:
      "In this new race, try a new track, a new way of playing, at the right time, pole jump over obstacles, the perfect jump will let you quickly surpass the opponent, faster to the end, become the first.",
    createdTime: "",
    url: "games/PoleVaultJump/",
    icon2Large:
      "game_pic/icon2_large/pole-vault-jump-257.webp",
    icon3Large:
      "game_pic/icon3_large/pole-vault-jump-257.webp",
    categoryName: "",
    categoryId: ",7,",
    intro:
      "In this new race, try a new track, a new way of playing, at the right time, pole jump over obstacles, the perfect jump will let you quickly surpass the opponent, faster to the end, become the first.",
    gamePlay:
      "In this new race, try a new track, a new way of playing, at the right time, pole jump over obstacles, the perfect jump will let you quickly surpass the opponent, faster to the end, become the first.",
    likeRatio: "0",
    likeCount: "0",
    dislikeCount: "55694",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/pole-vault-jump-257.webp",
  },
  {
    id: "258",
    path: "/games/morelist/258_Santa_Christmas_Run",
    name: "Santa Christmas Run",
    icon: "game_pic/icon/santa-christmas-run-258.webp",
    logo: "game_pic/icon2/santa-christmas-run-258.webp",
    image:
      "game_pic/icon3/santa-christmas-run-258.webp",
    icon4:
      "game_pic/icon4/santa-christmas-run-258.webp",
    icon5:
      "game_pic/icon_large/santa-christmas-run-258.webp",
    icon6:
      "game_pic/icon6/santa-christmas-run-258.webp",
    playCount: "705595",
    rating: "5.0",
    description:
      "Santa Christmas Run is a festive and addictive running platformer game featuring Santa Claus as the protagonist. With one-touch gameplay, players of all ages can enjoy this holiday-themed adventure. The game includes challenging levels, awesome graphics, and intuitive controls that will keep you entertained during the Christmas and New Year's Eve holidays. It's a delightful family adventure game to celebrate the season.",
    createdTime: "",
    url: "games/Santa_Christmas_Run/",
    icon2Large:
      "game_pic/icon2_large/santa-christmas-run-258.webp",
    icon3Large:
      "game_pic/icon3_large/santa-christmas-run-258.webp",
    categoryName: "",
    categoryId: ",7,68,",
    intro:
      "Join Santa in an exciting running platformer adventure in Santa Christmas Run, where you'll navigate challenging levels with one-touch gameplay, suitable for players of all ages.",
    gamePlay:
      "Run through challenging levels with Santa Claus, using one-touch controls to navigate obstacles and collect rewards.",
    likeRatio: "83",
    likeCount: "152267",
    dislikeCount: "31187",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/santa-christmas-run-258.webp",
  },
  {
    id: "259",
    path: "/games/morelist/259_Santa_Weightlifter",
    name: "Santa Weightlifter",
    icon: "game_pic/icon/santa-weightlifter-259.webp",
    logo: "game_pic/icon2/santa-weightlifter-259.webp",
    image:
      "game_pic/icon3/santa-weightlifter-259.webp",
    icon4:
      "game_pic/icon4/santa-weightlifter-259.webp",
    icon5:
      "game_pic/icon_large/santa-weightlifter-259.webp",
    icon6: "",
    playCount: "527604",
    rating: "5.0",
    description:
      "Santa Weightlifter is a casual game where Santa Claus stands on a platform, and your objective is to help him maintain his balance. You'll do this by clicking at the right moments to ensure Santa stays upright. The game features a straightforward gameplay mechanic that challenges you to earn points by keeping Santa balanced on the platform. It's a simple yet engaging experience that offers quick entertainment.",
    createdTime: "",
    url: "games/Santa_Weightlifter/",
    icon2Large:
      "game_pic/icon2_large/santa-weightlifter-259.webp",
    icon3Large:
      "game_pic/icon3_large/santa-weightlifter-259.webp",
    categoryName: "",
    categoryId: ",4,5,86,",
    intro:
      "Help Santa Claus maintain his balance on a platform in Santa Weightlifter, a casual game where you earn points through precise clicks.",
    gamePlay:
      "Click at the appropriate times to keep Santa Claus in balance on the platform and earn points.",
    likeRatio: "85",
    likeCount: "201809",
    dislikeCount: "35613",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/santa-weightlifter-259.webp",
  },
]