import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from "../../assert/styles/app.module.css";

export default function Header() {

  const location = useLocation();
  const [prefix, setPrefix]:any = useState('')
  
  useEffect(()=>{
    const path = location.pathname
    const routePath = path.substring(0, path.indexOf('/', 1));
    setPrefix(routePath)
  },[location])
  
  return (
    <div className={styles.header}>
        <Link to={`${prefix}/`}>
            <img alt='' className={styles.logo} src={require(`../../assert/imgs/logo${prefix.substring(1)}.png`)} />
        </Link>
    </div>
  )
}
