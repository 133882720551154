import React, { useEffect } from 'react'

import styles from "../../assert/styles/app.module.css";
import Footer from '../Footer';
import Header from '../Header';

export default function About() {


    useEffect(() => {
        // 组件挂载后，自动滚动到页面顶部
        window.scrollTo(0, 0);
    }, []); // 空数组依赖表示这个副作用只在组件挂载后执行一次


    return (
        <div className={styles.app}>
            <Header></Header>
            <div className="content">
                <div style={{ fontSize: ".96rem", color: "black", fontWeight: "bold", padding: ".8rem" }}>
                    About
                </div>
                <div style={{ fontSize: ".68rem", color: "#474747", lineHeight: "1rem", padding: "0.8rem", marginBottom: ".8rem" }}>
                    Welcome to 360adgames to play free online games in your area. There are new free games every day,
                    including
                    action games, adventure games, board and card games, multiplayer games, puzzle games, racing games,
                    skill
                    games, sports games and more addictive games. Play the best online and mobile games. We choose
                    characteristic shooting games, puzzle games, strategy games, war games and more! Join the world's best
                    free
                    online game site, and you can play thousands of 3D games, Unity games, webgl games, and the best mobile
                    games from Google Play and App Store without downloading! In addition, we provide all classNameic and retro
                    games, crosswords, driving games, girl games, bingo games, Pacman, Tetris, pinball, bricks and math
                    games
                    for kids and all families. Play our HTML5 games on all devices, mobile phones, tablets, computers, etc.
                    Enjoy the most interesting game with your friends.
                </div>
            </div>

            {<Footer/>}
        </div>
    )
}
