import React,{ useState,useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './index.module.css'

export default function Footer() {
  
  const location = useLocation();
  const [prefix, setPrefix]:any = useState('')
  
  useEffect(()=>{
    const path = location.pathname
    const routePath = path.substring(0, path.indexOf('/', 1));
    setPrefix(routePath)
  },[location])

  return (
    <div className={styles.footer_main}>
        <div className={styles.footer_content}>
          <Link to={`${prefix}/about`}>
            <div className={styles.foot_pd}>About</div>
          </Link>
          <div>|</div>
          <Link to={`${prefix}/privacy`}>
            <div className={styles.foot_pd}>Privacy Policy</div>
          </Link>
          <div>|</div>
          <Link to={`${prefix}/contact`}>
            <div className={styles.foot_pd}>Contact</div>
          </Link>
        </div>
      </div>
  )
}
