import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Games from '../../../commponents/Games'
import About from '../../../commponents/About'
import Privacy from '../../../commponents/Privacy'
import Contact from '../../../commponents/Contact'
import Home360ads from '../Home'

export default function Route360ads() {
    return (
        <Routes>
            <Route path='/home' element={<Home360ads />} />
            <Route index element={<Home360ads />} />
            <Route path="/games/*" element={<Games />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
        </Routes>
    )
}
