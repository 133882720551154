export const gameList = [
  {
    id: "1",
    path: "/games/morelist/1_Tomb_Runner",
    name: "Tomb Runner",
    icon: "game_pic/icon/tomb-runner-1.webp",
    logo: "game_pic/icon2/tomb-runner-1.webp",
    image: "game_pic/icon3/tomb-runner-1.webp",
    icon4: "game_pic/icon4/tomb-runner-1.webp",
    icon5: "game_pic/icon_large/tomb-runner-1.webp",
    icon6: "game_pic/icon6/tomb-runner-1.webp",
    playCount: "494064",
    rating: "5.0",
    description:
      "Embark on an endless running adventure in Tomb Runner, where you'll run, jump, slide, rush, and surf through temples, exotic landscapes, bridges, and subways while collecting gems, special powers, and coins, all with the goal of unlocking cool characters and experiencing endless running fun.",
    createdTime: "",
    url: "games/TombRunner/",
    icon2Large: "game_pic/icon2_large/tomb-runner-1.webp",
    icon3Large: "game_pic/icon3_large/tomb-runner-1.webp",
    categoryName: "",
    categoryId: ",7,67,68,59,",
    intro:
      "Embark on an endless running adventure in Tomb Runner, where you'll run, jump, slide, rush, and surf through temples, exotic landscapes, bridges, and subways while collecting gems, special powers, and coins, all with the goal of unlocking cool characters and experiencing endless running fun.",
    gamePlay:
      "Run, jump, slide, rush, and surf through various environments while collecting gems, special powers, and coins. Unlock characters with unique abilities for endless running fun.",
    likeRatio: "90",
    likeCount: "80038",
    dislikeCount: "8893",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/tomb-runner-1.webp",
  },
  {
    id: "2",
    path: "/games/morelist/2_MotoX3M_6_Spooky_Land",
    name: "MotoX3M 6 Spooky Land",
    icon: "game_pic/icon/motox3m-6-spooky-land-2.webp",
    logo: "game_pic/icon2/motox3m-6-spooky-land-2.webp",
    image: "game_pic/icon3/motox3m-6-spooky-land-2.webp",
    icon4: "game_pic/icon4/motox3m-6-spooky-land-2.webp",
    icon5: "game_pic/icon_large/motox3m-6-spooky-land-2.webp",
    icon6: "",
    playCount: "551200",
    rating: "4.5",
    description:
      "MotoX3M 6 Spooky Land thrusts players into a spine-tingling motorcycle racing experience set in a dark and eerie atmosphere. You'll operate a bone-shaped motorcycle as you navigate through haunting levels filled with traps and obstacles. The game challenges your skills and reflexes as you strive to complete each level without crashing. With its spooky theme and thrilling gameplay, MotoX3M 6 Spooky Land provides a hair-raising racing adventure for players who enjoy a touch of the macabre.",
    createdTime: "",
    url: "games/MotoX3MSpookyLand/",
    icon2Large: "game_pic/icon2_large/motox3m-6-spooky-land-2.webp",
    icon3Large: "game_pic/icon3_large/motox3m-6-spooky-land-2.webp",
    categoryName: "",
    categoryId: ",6,59,",
    intro:
      "Experience spine-chilling motorcycle action in MotoX3M 6 Spooky Land as you operate a bone-shaped motorcycle in a dark and terfrifying atmosphere.",
    gamePlay:
      "Operate a bone-shaped motorcycle through dark and spooky levels, avoiding traps and obstacles to complete each level.",
    likeRatio: "90",
    likeCount: "99216",
    dislikeCount: "11024",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/motox3m-6-spooky-land-2.webp",
  },
  {
    id: "3",
    path: "/games/morelist/3_Shooter_Rush",
    name: "Shooter Rush",
    icon: "game_pic/icon/shooter-rush-3.webp",
    logo: "game_pic/icon2/shooter-rush-3.webp",
    image: "game_pic/icon3/shooter-rush-3.webp",
    icon4: "game_pic/icon4/shooter-rush-3.webp",
    icon5: "game_pic/icon_large/shooter-rush-3.webp",
    icon6: "",
    playCount: "420758",
    rating: "4.5",
    description:
      "Shooter Rush immerses players in intense shooting action as they move around with their character and face off against waves of relentless enemies. Your objective is to eliminate hundreds of foes in a frantic battle, and if you're skilled enough, you can trigger a firemode to take out dozens of them at once. The game offers non-stop shooting excitement with fast-paced gameplay and a variety of enemies to face. Shooter Rush is perfect for players who crave intense shooting challenges and action-packed mayhem.",
    createdTime: "",
    url: "games/ShooterRush/",
    icon2Large: "game_pic/icon2_large/shooter-rush-3.webp",
    icon3Large: "game_pic/icon3_large/shooter-rush-3.webp",
    categoryName: "",
    categoryId: ",60,7,75,",
    intro:
      "Enter the chaotic world of Shooter Rush, where you move around with your character and engage in frantic shooting action to eliminate hordes of enemies, with the potential to trigger firemode for massive eliminations.",
    gamePlay:
      "Use your mouse cursor or your finger to select, drag and move people.",
    likeRatio: "88",
    likeCount: "37027",
    dislikeCount: "5049",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/shooter-rush-3.webp",
  },
  {
    id: "4",
    path: "/games/morelist/4_Grand_Prix_Hero",
    name: "Grand Prix Hero",
    icon: "game_pic/icon/grand-prix-hero-4.webp",
    logo: "game_pic/icon2/grand-prix-hero-4.webp",
    image: "game_pic/icon3/grand-prix-hero-4.webp",
    icon4: "game_pic/icon4/grand-prix-hero-4.webp",
    icon5: "game_pic/icon_large/grand-prix-hero-4.webp",
    icon6: "",
    playCount: "458939",
    rating: "4.5",
    description:
      "Grand Prix Hero offers thrilling racing action as you compete in the Grand Prix Series across various continents. You'll need to navigate through traffic, boost at high speeds, and collect coins to upgrade your car for the next race. The game combines skillful driving with strategic upgrades, allowing you to customize your vehicle for optimal performance. Grand Prix Hero is a fast-paced racing experience that tests your reflexes and decision-making as you aim to become the ultimate racing champion.",
    createdTime: "",
    url: "games/GrandPrixHero/",
    icon2Large: "game_pic/icon2_large/grand-prix-hero-4.webp",
    icon3Large: "game_pic/icon3_large/grand-prix-hero-4.webp",
    categoryName: "",
    categoryId: ",6,67,",
    intro:
      "Compete in the Grand Prix Series across multiple continents in Grand Prix Hero, where you'll dodge traffic, boost at high speeds, collect coins, and upgrade your car for the next race.",
    gamePlay:
      "Compete in the Grand Prix Series, dodge traffic, collect coins, and upgrade your car for the next race to become the Grand Prix Hero.",
    likeRatio: "76",
    likeCount: "38367",
    dislikeCount: "12116",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/grand-prix-hero-4.webp",
  },
  {
    id: "5",
    path: "/games/morelist/5_Superbike_Hero",
    name: "Superbike Hero",
    icon: "game_pic/icon/superbike-hero-5.webp",
    logo: "game_pic/icon2/superbike-hero-5.webp",
    image: "game_pic/icon3/superbike-hero-5.webp",
    icon4: "game_pic/icon4/superbike-hero-5.webp",
    icon5: "game_pic/icon_large/superbike-hero-5.webp",
    icon6: "",
    playCount: "291773",
    rating: "4.5",
    description:
      "Superbike Hero offers thrilling motorcycle racing as you compete across Europe on your superbike. Your goal is to collect coins, upgrade your bike, and navigate through tight corners to win races. The game features the excitement of high-speed racing with the added challenge of mastering tricky turns. With multiple difficulty modes to choose from, Superbike Hero offers a customizable racing experience for players who want to prove themselves as the ultimate superbike hero.",
    createdTime: "",
    url: "games/SuperbikeHero/",
    icon2Large: "game_pic/icon2_large/superbike-hero-5.webp",
    icon3Large: "game_pic/icon3_large/superbike-hero-5.webp",
    categoryName: "",
    categoryId: ",6,7,",
    intro:
      "Race across Europe on your superbike, collecting coins, upgrading your bike, and mastering tight corners in the high-speed action of Superbike Hero.",
    gamePlay:
      "Race across Europe on your superbike, collect coins, upgrade your bike, and navigate tight corners to win races.",
    likeRatio: "76",
    likeCount: "59872",
    dislikeCount: "18907",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/superbike-hero-5.webp",
  },
  {
    id: "6",
    path: "/games/morelist/6_Ranger_Action",
    name: "Ranger Action",
    icon: "game_pic/icon/ranger-action-6.webp",
    logo: "game_pic/icon2/ranger-action-6.webp",
    image: "game_pic/icon3/ranger-action-6.webp",
    icon4: "game_pic/icon4/ranger-action-6.webp",
    icon5: "game_pic/icon_large/ranger-action-6.webp",
    icon6: "",
    playCount: "72048",
    rating: "4.5",
    description:
      "Ranger Action puts you in the shoes of a fearless ranger tasked with exterminating zombies on both land and in the air. Armed with shotguns and special skills like Firestorm, Lightning, and Cold Ice, you'll battle hordes of undead foes. Collect items and upgrades as you unleash devastating attacks on the zombie menace. Ranger Action offers intense zombie-shooting action with a variety of weapons and skills to master.",
    createdTime: "",
    url: "games/RangerAction/",
    icon2Large: "game_pic/icon2_large/ranger-action-6.webp",
    icon3Large: "game_pic/icon3_large/ranger-action-6.webp",
    categoryName: "",
    categoryId: ",7,60,59,",
    intro:
      "Take on the role of a brave ranger and eliminate zombies in the air and on land using a variety of weapons and special skills in the action-packed Ranger Action game.",
    gamePlay:
      "Play as a brave ranger and eliminate zombies on land and in the air using shotguns and special skills.",
    likeRatio: "72",
    likeCount: "8819",
    dislikeCount: "3430",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/ranger-action-6.webp",
  },
  {
    id: "7",
    path: "/games/morelist/7_Africa_Soccer_Run",
    name: "Africa Soccer Run",
    icon: "game_pic/icon/africa-soccer-run-7.webp",
    logo: "game_pic/icon2/africa-soccer-run-7.webp",
    image: "game_pic/icon3/africa-soccer-run-7.webp",
    icon4: "game_pic/icon4/africa-soccer-run-7.webp",
    icon5: "game_pic/icon_large/africa-soccer-run-7.webp",
    icon6: "",
    playCount: "74420",
    rating: "4.5",
    description:
      "Africa Soccer Run is a parkour game that invites players to embark on an athletic adventure in Africa. Your mission is to collect footballs scattered along your path while skillfully avoiding obstacles. The game combines the excitement of parkour with the love of football (soccer) in an immersive gameplay experience. Test your reflexes and agility as you leap, slide, and jump your way to collect footballs in Africa Soccer Run.",
    createdTime: "",
    url: "games/AfricaSoccerRun/",
    icon2Large: "game_pic/icon2_large/africa-soccer-run-7.webp",
    icon3Large: "game_pic/icon3_large/africa-soccer-run-7.webp",
    categoryName: "",
    categoryId: ",5,68,79,",
    intro:
      "Embark on a parkour adventure in Africa Soccer Run, collecting footballs while navigating obstacles on your path.",
    gamePlay:
      "Players control the character's movements by tapping or swiping the screen to jump over obstacles.",
    likeRatio: "74",
    likeCount: "7159",
    dislikeCount: "2515",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/africa-soccer-run-7.webp",
  },
  {
    id: "8",
    path: "/games/morelist/8_Bubble_Touch",
    name: "Bubble Touch",
    icon: "game_pic/icon/bubble-touch-8.webp",
    logo: "game_pic/icon2/bubble-touch-8.webp",
    image: "game_pic/icon3/bubble-touch-8.webp",
    icon4: "game_pic/icon4/bubble-touch-8.webp",
    icon5: "game_pic/icon_large/bubble-touch-8.webp",
    icon6: "",
    playCount: "145959",
    rating: "4.5",
    description:
      "Bubble Touch offers a simple yet engaging clicking game where your task is to pop bubbles. Click with your finger or mouse to burst the bubbles before they ascend above the water's surface. The game challenges your timing and precision as you strive to pop as many bubbles as possible. Bubble Touch provides a casual and entertaining clicking experience suitable for players of all ages.",
    createdTime: "",
    url: "games/BubbleTouch/",
    icon2Large: "game_pic/icon2_large/bubble-touch-8.webp",
    icon3Large: "game_pic/icon3_large/bubble-touch-8.webp",
    categoryName: "",
    categoryId: ",4,64,86,70,",
    intro:
      "In Bubble Touch, it's a click game where you must pop bubbles by clicking with your finger or mouse before they rise above the water level.",
    gamePlay:
      "Click with your finger or mouse to pop bubbles before they rise above the water level.",
    likeRatio: "71",
    likeCount: "10363",
    dislikeCount: "4232",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/bubble-touch-8.webp",
  },
  {
    id: "9",
    path: "/games/morelist/9_Hot_Shoot",
    name: "Hot Shoot",
    icon: "game_pic/icon/hot-shoot-9.webp",
    logo: "game_pic/icon2/hot-shoot-9.webp",
    image: "game_pic/icon3/hot-shoot-9.webp",
    icon4: "game_pic/icon4/hot-shoot-9.webp",
    icon5: "game_pic/icon_large/hot-shoot-9.webp",
    icon6: "",
    playCount: "496534",
    rating: "4.5",
    description:
      "Hot Shoot is a fast-paced shooting game that challenges your precision and reflexes. You control a rotating muzzle that can fire in any direction to eliminate small balls surrounding it. Your objective is to clear as many balls as possible within a limited time frame. The game becomes increasingly challenging as more balls appear on the screen. Hot Shoot offers addictive gameplay with a focus on achieving the highest score by shooting down balls in a dynamic, 360-degree environment.",
    createdTime: "",
    url: "games/HotShoot/",
    icon2Large: "game_pic/icon2_large/hot-shoot-9.webp",
    icon3Large: "game_pic/icon3_large/hot-shoot-9.webp",
    categoryName: "",
    categoryId: ",60,65,64,",
    intro:
      "In Hot Shoot, you control a rotating muzzle capable of a 360-degree rotation to shoot down small balls on the outside, aiming to achieve a high score by clearing as many balls as possible.",
    gamePlay:
      "Control a rotating muzzle to shoot down small balls in a 360-degree environment, aiming for a high score.",
    likeRatio: "90",
    likeCount: "71501",
    dislikeCount: "7945",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/hot-shoot-9.webp",
  },
  {
    id: "293",
    path: "/games/morelist/293_Bubble_Shooter_Pro",
    name: "Bubble Shooter Pro",
    icon: "game_pic/icon/bubble-shooter-pro-293.webp",
    logo: "game_pic/icon2/bubble-shooter-pro-293.webp",
    image: "game_pic/icon3/bubble-shooter-pro-293.webp",
    icon4: "game_pic/icon4/bubble-shooter-pro-293.webp",
    icon5: "game_pic/icon_large/bubble-shooter-pro-293.webp",
    icon6: "game_pic/icon6/bubble-shooter-pro-293-1.webp",
    playCount: "502148",
    rating: "5.0",
    description:
      "Bubble Shooter Pro is a captivating bubble shooter game where your goal is to clear the board by matching and popping bubbles of the same color. Aim carefully and shoot bubbles to create clusters of two or more of the same color, causing them to burst. The game features a variety of challenging levels, and the more bubbles you clear in a single shot, the higher your score. Be cautious; if you fail to eliminate bubbles, new ones will appear at the top, increasing the pressure. It's a classic bubble shooter experience that's hard to put down.",
    createdTime: "",
    url: "games/Puzzle_Bobble/",
    icon2Large: "game_pic/icon2_large/bubble-shooter-pro-293.webp",
    icon3Large: "game_pic/icon3_large/bubble-shooter-pro-293.webp",
    categoryName: "",
    categoryId: ",64,84,70,",
    intro:
      "Get ready for hours of highly addictive bubble-popping fun in Bubble Shooter Pro.",
    gamePlay:
      "Aim and shoot bubbles at the cluster of bubbles at the top, matching them with bubbles of the same color to make them pop and clear the board.",
    likeRatio: "89",
    likeCount: "192172",
    dislikeCount: "23751",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/bubble-shooter-pro-293.webp",
  },
  {
    id: "27",
    path: "/games/morelist/27_Candy_Time",
    name: "Candy Time",
    icon: "game_pic/icon/candy-time-27.webp",
    logo: "game_pic/icon2/candy-time-27.webp",
    image: "game_pic/icon3/candy-time-27.webp",
    icon4: "game_pic/icon4/candy-time-27.webp",
    icon5: "game_pic/icon_large/candy-time-27.webp",
    icon6: "game_pic/icon6/candy-match-27(1).webp",
    playCount: "518927",
    rating: "4.5",
    description:
      "Candy Time is a match-3 game that challenges you to reach the highest level by completing score tasks. Your objective is to swap the positions of candies on the board, creating matches of three or more identical candies to earn points. Each level presents a specific score target that you must achieve to progress. With a variety of levels and increasingly demanding tasks, Candy Time offers a satisfying and challenging match-3 experience.",
    createdTime: "",
    url: "games/CandyTime/",
    icon2Large: "game_pic/icon2_large/candy-time-27.webp",
    icon3Large: "game_pic/icon3_large/candy-time-27.webp",
    categoryName: "",
    categoryId: ",64,85,",
    intro:
      "In Candy Time, your mission is to achieve the highest possible level by completing score tasks in this match-3 game.",
    gamePlay:
      "Swap candy positions on the board to create matches of three or more identical candies and reach the required score for each level.",
    likeRatio: "86",
    likeCount: "182973",
    dislikeCount: "29787",
    displayMethod: "",
    icon4Large: "game_pic/icon4_large/candy-time-27.webp",
  },
  {
    id: "1027",
    path: "/games/morelist/1027_Coloruid",
    name: "Coloruid",
    icon: "game_pic/icon/coloruid-1027.webp",
    logo: "",
    image: "",
    icon4: "",
    icon5: "game_pic/icon_large/coloruid-1027.webp",
    icon6: "game_pic/icon6/coloruid-1027.webp",
    playCount: "127980",
    rating: "4.5",
    description:
      "In Coloruid, players aim to unify the colors of smile boxes by modifying their colors with clicks. Plan your clicks carefully to minimize the number of moves required to achieve color unification. With each level presenting new challenges and obstacles, test your puzzle-solving skills and aim for efficiency in Color Fusion.\n",
    createdTime: "",
    url: "games/COLOSmiles1027AZBG/",
    icon2Large: "",
    icon3Large: "",
    categoryName: "",
    categoryId: ",4,3,",
    intro:
      "Welcome to Coloruid, a challenging puzzle game where you must use the least number of clicks to modify the color of smile boxes, unifying all the different smile boxes into one color.\n",
    gamePlay:
      "Coloruid presents players with a series of smile boxes, each with a different color. The objective is to unify all the smile boxes into one color using the least number of clicks possible. Strategize your clicks to achieve color fusion and solve each level's puzzle.\n",
    likeRatio: "72",
    likeCount: "11978",
    dislikeCount: "4659",
    displayMethod: "",
    icon4Large: "",
  },
  {
    id: "12",
    path: "/games/morelist/12_Big_Win_Basketball",
    name: "Big Win Basketball",
    icon: "game_pic/icon/big-win-basketball-12.webp",
    logo: "game_pic/icon2/big-win-basketball-12.webp",
    image:
      "game_pic/icon3/big-win-basketball-12.webp",
    icon4:
      "game_pic/icon4/big-win-basketball-12.webp",
    icon5:
      "game_pic/icon_large/big-win-basketball-12.webp",
    icon6: "",
    playCount: "88443",
    rating: "4.5",
    description:
      "Big Win Basketball combines basketball skills with prediction challenges. You must calculate the shot based on the parabola, aiming to throw the basketball into the hoop to score. Don't forget to collect stars scattered around the court to earn additional points. The game adds an extra layer of difficulty by limiting the number of basketballs available in each game. Big Win Basketball tests your accuracy and prediction skills as you strive to achieve the highest score with limited shots.",
    createdTime: "",
    url: "games/Bigwinbasketball/",
    icon2Large:
      "game_pic/icon2_large/big-win-basketball-12.webp",
    icon3Large:
      "game_pic/icon3_large/big-win-basketball-12.webp",
    categoryName: "",
    categoryId: ",5,79,",
    intro:
      "In Big Win Basketball, use your prediction skills to estimate the parabolic trajectory of your basketball shots, aim for the hoop, score points, and collect stars while managing limited basketballs.",
    gamePlay:
      "Predict the shot trajectory based on the parabola, throw basketballs into the hoop, collect stars, and manage limited basketballs.",
    likeRatio: "83",
    likeCount: "16150",
    dislikeCount: "3308",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/big-win-basketball-12.webp",
  },
  {
    id: "13",
    path: "/games/morelist/13_Gravity_Soccer",
    name: "Gravity Soccer",
    icon: "game_pic/icon/gravity-soccer-13.webp",
    logo: "game_pic/icon2/gravity-soccer-13.webp",
    image: "game_pic/icon3/gravity-soccer-13.webp",
    icon4: "game_pic/icon4/gravity-soccer-13.webp",
    icon5:
      "game_pic/icon_large/gravity-soccer-13.webp",
    icon6: "",
    playCount: "187624",
    rating: "4.5",
    description:
      "Gravity Soccer offers a unique puzzle experience where you manipulate gravity to guide a football to its goal. Click to strategically remove stone bricks that block the ball's path. By eliminating the right bricks at the right time, you can create a gravity-driven path for the ball to score. The game features increasingly challenging levels with intricate puzzles to solve. Gravity Soccer combines physics-based gameplay with soccer excitement in a captivating way.",
    createdTime: "",
    url: "games/Gravitysoccer/",
    icon2Large:
      "game_pic/icon2_large/gravity-soccer-13.webp",
    icon3Large:
      "game_pic/icon3_large/gravity-soccer-13.webp",
    categoryName: "",
    categoryId: ",5,86,79,",
    intro:
      "In Gravity Soccer, you must click to eliminate stone bricks strategically, allowing the football to score smoothly by controlling the force of gravity.",
    gamePlay:
      "Click to eliminate stone bricks and manipulate gravity to guide the football to score.",
    likeRatio: "87",
    likeCount: "52235",
    dislikeCount: "7805",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/gravity-soccer-13.webp",
  },
  {
    id: "14",
    path: "/games/morelist/14_Extreme_Followers",
    name: "Extreme Followers",
    icon: "game_pic/icon/extreme-followers-14.webp",
    logo: "game_pic/icon2/extreme-followers-14.webp",
    image:
      "game_pic/icon3/extreme-followers-14.webp",
    icon4:
      "game_pic/icon4/extreme-followers-14.webp",
    icon5:
      "game_pic/icon_large/extreme-followers-14.webp",
    icon6: "",
    playCount: "63292",
    rating: "4.5",
    description:
      "Extreme Followers is an addictive and unique game where you play as a charismatic leader looking to gain followers. Your objective is to absorb followers by moving around and expanding your circle of influence. As your following grows, so does your power. However, you must avoid other leaders who are also trying to expand their followers. Extreme Followers offers a competitive and strategic experience as you strive to become the most influential leader on the board.",
    createdTime: "",
    url: "games/ExtremeFollowers/",
    icon2Large:
      "game_pic/icon2_large/extreme-followers-14.webp",
    icon3Large:
      "game_pic/icon3_large/extreme-followers-14.webp",
    categoryName: "",
    categoryId: ",73,66,",
    intro:
      "In Extreme Followers, your goal is to absorb followers to expand your power, building an ever-growing army of loyal followers.",
    gamePlay:
      "Absorb followers by expanding your circle of influence, while avoiding other leaders.",
    likeRatio: "76",
    likeCount: "9620",
    dislikeCount: "3038",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/extreme-followers-14.webp",
  },
  {
    id: "15",
    path: "/games/morelist/15_Magic_Girl",
    name: "Magic Girl",
    icon: "game_pic/icon/magic-girl-15.webp",
    logo: "game_pic/icon2/magic-girl-15.webp",
    image: "game_pic/icon3/magic-girl-15.webp",
    icon4: "game_pic/icon4/magic-girl-15.webp",
    icon5: "game_pic/icon_large/magic-girl-15.webp",
    icon6: "",
    playCount: "343086",
    rating: "4.5",
    description:
      "Magic Girl lets you unleash your inner fashion designer as you dress up the main character in stunning outfits. With a variety of clothing options and accessories, you can create unique looks for the Magic Girl. Mix and match different styles, experiment with colors, and accessorize to your heart's content. The game offers a creative and relaxing experience for those who love fashion and style.",
    createdTime: "",
    url: "games/MagicGirl4/",
    icon2Large:
      "game_pic/icon2_large/magic-girl-15.webp",
    icon3Large:
      "game_pic/icon3_large/magic-girl-15.webp",
    categoryName: "",
    categoryId: ",8,87,",
    intro:
      "Transform the Magic Girl into a stylish icon by dressing her up and choosing cool outfits in this fashion-focused game.",
    gamePlay:
      "Dress up the Magic Girl by selecting outfits, accessories, and styling options to create unique looks.",
    likeRatio: "82",
    likeCount: "50639",
    dislikeCount: "11116",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/magic-girl-15.webp",
  },
  {
    id: "16",
    path: "/games/morelist/16_Dunk_Shot",
    name: "Dunk Shot",
    icon: "game_pic/icon/dunk-shot-16.webp",
    logo: "game_pic/icon2/dunk-shot-16.webp",
    image: "game_pic/icon3/dunk-shot-16.webp",
    icon4: "game_pic/icon4/dunk-shot-16.webp",
    icon5: "game_pic/icon_large/dunk-shot-16.webp",
    icon6: "",
    playCount: "316554",
    rating: "4.5",
    description:
      "Dunk Shot is an addictive basketball game where you take control of a character and aim for epic slam dunks. Your objective is to jump at the right moment and shoot the basketball through the hoop. The game offers a variety of characters to choose from, each with unique skills. As you progress, the difficulty increases, challenging your timing and aiming skills. Dunk Shot is all about achieving the perfect dunk and mastering the art of basketball.",
    createdTime: "",
    url: "games/Dunkshot/",
    icon2Large:
      "game_pic/icon2_large/dunk-shot-16.webp",
    icon3Large:
      "game_pic/icon3_large/dunk-shot-16.webp",
    categoryName: "",
    categoryId: ",5,81,79,",
    intro:
      "In Dunk Shot, you choose a character, jump, and aim for the perfect shot to slam dunk the basketball.",
    gamePlay:
      "Choose a character, jump, and aim for the perfect slam dunk by shooting the basketball through the hoop.",
    likeRatio: "85",
    likeCount: "64577",
    dislikeCount: "11395",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/dunk-shot-16.webp",
  },
  {
    id: "17",
    path: "/games/morelist/17_Light_Refraction",
    name: "Light Refraction",
    icon: "game_pic/icon/light-refraction-17.webp",
    logo: "game_pic/icon2/light-refraction-17.webp",
    image: "game_pic/icon3/light-refraction-17.webp",
    icon4: "game_pic/icon4/light-refraction-17.webp",
    icon5:
      "game_pic/icon_large/light-refraction-17.webp",
    icon6: "",
    playCount: "543515",
    rating: "4.5",
    description:
      "Light Refraction offers a unique puzzle experience where you manipulate reflective sheets to control the path of light beams. Your objective is to prevent the beams from converging at a specific point by strategically placing and adjusting the reflective sheets. As you progress, the puzzles become more complex, requiring precise planning and logic. Light Refraction offers a series of mind-bending challenges that test your ability to control and redirect light.",
    createdTime: "",
    url: "games/LightRefraction/",
    icon2Large:
      "game_pic/icon2_large/light-refraction-17.webp",
    icon3Large:
      "game_pic/icon3_large/light-refraction-17.webp",
    categoryName: "",
    categoryId: ",3,61,",
    intro:
      "In Light Refraction, your task is to prevent reflective sheets from directing light beams to a fixed place, challenging your strategic thinking and problem-solving skills.",
    gamePlay:
      "Prevent reflective sheets from directing light beams to a fixed location by strategically placing and adjusting them.",
    likeRatio: "87",
    likeCount: "160772",
    dislikeCount: "24024",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/light-refraction-17.webp",
  },
  {
    id: "18",
    path: "/games/morelist/18_Pocket_Plants_Garden",
    name: "Pocket Plants Garden",
    icon: "game_pic/icon/pocket-plants-garden-18.webp",
    logo: "game_pic/icon2/pocket-plants-garden-18.webp",
    image:
      "game_pic/icon3/pocket-plants-garden-18.webp",
    icon4:
      "game_pic/icon4/pocket-plants-garden-18.webp",
    icon5:
      "game_pic/icon_large/pocket-plants-garden-18.webp",
    icon6: "",
    playCount: "558911",
    rating: "4.5",
    description:
      "Pocket Plants Garden is a relaxing and rewarding gardening game where you cultivate a variety of plants. Plant seeds, wait for them to mature and upgrade, and in return, they generate currency for you. This currency can be used to unlock new features, purchase more plants, and expand your garden. The game offers a soothing and enjoyable experience for players who love gardening and watching their efforts bear fruit.",
    createdTime: "",
    url: "games/PocketPlantsGarden/",
    icon2Large:
      "game_pic/icon2_large/pocket-plants-garden-18.webp",
    icon3Large:
      "game_pic/icon3_large/pocket-plants-garden-18.webp",
    categoryName: "",
    categoryId: ",4,85,73,",
    intro:
      "Pocket Plants Garden invites you to plant and nurture various plants, watch them grow, and generate currency that can be used to unlock new features and more diverse plants.",
    gamePlay:
      "Plant and nurture various plants, collect currency from their growth, and use it to unlock new features and acquire more plants.",
    likeRatio: "84",
    likeCount: "65728",
    dislikeCount: "12520",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/pocket-plants-garden-18.webp",
  },
  {
    id: "19",
    path: "/games/morelist/19_Sweet_World",
    name: "Sweet World",
    icon: "game_pic/icon/sweet-world-19.webp",
    logo: "game_pic/icon2/sweet-world-19.webp",
    image: "game_pic/icon3/sweet-world-19.webp",
    icon4: "game_pic/icon4/sweet-world-19.webp",
    icon5: "game_pic/icon_large/sweet-world-19.webp",
    icon6: "",
    playCount: "554511",
    rating: "4.5",
    description:
      "Sweet World is a fast-paced puzzle game where you link together matching candies to clear them from the board. Your objective is to eliminate as many candies as possible within a set time limit, earning more points for larger combinations. The game provides a challenging and addicting experience as you strive to beat the clock and achieve high scores. Sweet World offers a sweet and colorful adventure for fans of match-three games.",
    createdTime: "",
    url: "games/Sweetworld/",
    icon2Large:
      "game_pic/icon2_large/sweet-world-19.webp",
    icon3Large:
      "game_pic/icon3_large/sweet-world-19.webp",
    categoryName: "",
    categoryId: ",64,61,85,",
    intro:
      "In Sweet World, your goal is to connect three or more identical candies to eliminate them within a specified time frame, aiming to achieve higher scores.",
    gamePlay:
      "Connect three or more identical candies to eliminate them within a specified time frame and aim for higher scores.",
    likeRatio: "83",
    likeCount: "105856",
    dislikeCount: "21681",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/sweet-world-19.webp",
  },
  {
    id: "21",
    path: "/games/morelist/21_Swipe_Basketball",
    name: "Swipe Basketball",
    icon: "game_pic/icon/swipe-basketball-21.webp",
    logo: "game_pic/icon2/swipe-basketball-21.webp",
    image:
      "game_pic/icon3/swipe-basketball-21.webp",
    icon4:
      "game_pic/icon4/swipe-basketball-21.webp",
    icon5:
      "game_pic/icon_large/swipe-basketball-21.webp",
    icon6: "",
    playCount: "158588",
    rating: "4.5",
    description:
      "Swipe Basketball is an addictive basketball game that tests your aiming and timing skills. Swipe your finger to launch the basketball towards the hoop, trying to score points and collect bonuses along the way. Each successful shot earns you points, but if you miss a hoop, you'll lose one of your limited balls. The game offers a challenging and fast-paced experience where precision and strategy are key to achieving high scores. Swipe Basketball is perfect for fans of quick and competitive gameplay.",
    createdTime: "",
    url: "games/SwipeBasketball/",
    icon2Large:
      "game_pic/icon2_large/swipe-basketball-21.webp",
    icon3Large:
      "game_pic/icon3_large/swipe-basketball-21.webp",
    categoryName: "",
    categoryId: ",5,79,",
    intro:
      "In Swipe Basketball, your objective is to swipe the ball towards the hoop to score while aiming for bonus points, but be cautious�if you miss a hoop, you lose a ball!",
    gamePlay:
      "Swipe your finger to launch the basketball towards the hoop, aiming for score bonuses while avoiding missing a hoop to keep your balls.",
    likeRatio: "83",
    likeCount: "32907",
    dislikeCount: "6740",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/swipe-basketball-21.webp",
  },
  {
    id: "22",
    path: "/games/morelist/22_Zombie_Run_Saga",
    name: "Zombie Run Saga",
    icon: "game_pic/icon/zombie-run-saga-22.webp",
    logo: "game_pic/icon2/zombie-run-saga-22.webp",
    image:
      "game_pic/icon3/zombie-run-saga-22.webp",
    icon4:
      "game_pic/icon4/zombie-run-saga-22.webp",
    icon5:
      "game_pic/icon_large/zombie-run-saga-22.webp",
    icon6: "",
    playCount: "522969",
    rating: "4.5",
    description:
      "Zombie Run Saga is an action-packed game where you take on the role of a zombie trying to escape from pursuing plants. Run as fast as you can to avoid being caught by the relentless vegetation. The game becomes increasingly challenging as you encounter more obstacles and hazards. Zombie Run Saga combines fast-paced running gameplay with a unique twist, making it a thrilling experience for players who love action and adventure.",
    createdTime: "",
    url: "games/ZombieRunSaga/",
    icon2Large:
      "game_pic/icon2_large/zombie-run-saga-22.webp",
    icon3Large:
      "game_pic/icon3_large/zombie-run-saga-22.webp",
    categoryName: "",
    categoryId: ",59,83,66,",
    intro:
      "In Zombie Run Saga, you play as a zombie, and your goal is to move quickly to avoid being attacked by relentless plants.",
    gamePlay:
      "Control your zombie character, run quickly to evade attacking plants, and navigate through challenging obstacles.",
    likeRatio: "85",
    likeCount: "106686",
    dislikeCount: "18827",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/zombie-run-saga-22.webp",
  },
  {
    id: "23",
    path: "/games/morelist/23_Tap_and_Go",
    name: "Tap and Go",
    icon: "game_pic/icon/tap-and-go-23.webp",
    logo: "game_pic/icon2/tap-and-go-23.webp",
    image: "game_pic/icon3/tap-and-go-23.webp",
    icon4: "game_pic/icon4/tap-and-go-23.webp",
    icon5:
      "game_pic/icon_large/tap-and-go-23.webp",
    icon6: "",
    playCount: "330582",
    rating: "4.5",
    description:
      "Tap and Go is an exciting parkour game featuring a cute little duck. Your goal is to help the duck navigate a challenging course by tapping the screen at specific moments to make turns and avoid obstacles. Timing is essential as you test your reflexes and aim for a high score. The game offers increasingly difficult levels that challenge your ability to react quickly and make precise moves. Tap and Go is a fun and fast-paced game for players who enjoy reflex-based challenges.",
    createdTime: "",
    url: "games/TapAndGo/",
    icon2Large:
      "game_pic/icon2_large/tap-and-go-23.webp",
    icon3Large:
      "game_pic/icon3_large/tap-and-go-23.webp",
    categoryName: "",
    categoryId: ",61,86,",
    intro:
      "In Tap and Go, you control a little duck in a parkour adventure, where tapping the screen at precise moments is crucial to test your reflexes.",
    gamePlay:
      "Control the little duck's movements by tapping the screen at the right moments to make turns and overcome obstacles.",
    likeRatio: "74",
    likeCount: "34248",
    dislikeCount: "12033",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/tap-and-go-23.webp",
  },
  {
    id: "24",
    path: "/games/morelist/24_Airport_Rush",
    name: "Airport Rush",
    icon: "game_pic/icon/airport-rush-24.webp",
    logo: "game_pic/icon2/airport-rush-24.webp",
    image: "game_pic/icon3/airport-rush-24.webp",
    icon4: "game_pic/icon4/airport-rush-24.webp",
    icon5:
      "game_pic/icon_large/airport-rush-24.webp",
    icon6: "",
    playCount: "74004",
    rating: "4.5",
    description:
      "Airport Rush is a challenging simulation game that puts you in control of managing airport operations. Your task is to plan and organize the takeoff process for multiple aircraft, avoiding collisions and ensuring efficient departures. As the game progresses, the complexity increases, and you must manage various types of planes and deal with unexpected events. Airport Rush offers a strategic and engaging experience for players who enjoy simulation and time-management games.",
    createdTime: "",
    url: "games/AirportRush/",
    icon2Large:
      "game_pic/icon2_large/airport-rush-24.webp",
    icon3Large:
      "game_pic/icon3_large/airport-rush-24.webp",
    categoryName: "",
    categoryId: ",66,73,",
    intro:
      "In Airport Rush, you must plan the process and sequence for multiple aircraft to take off, ensuring smooth operations and avoiding crashes.",
    gamePlay:
      "Plan the sequence and timing for multiple aircraft to take off safely, preventing crashes and ensuring smooth operations.",
    likeRatio: "84",
    likeCount: "23000",
    dislikeCount: "4381",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/airport-rush-24.webp",
  },
  {
    id: "25",
    path: "/games/morelist/25_Candy_Forest",
    name: "Candy Forest",
    icon: "game_pic/icon/candy-forest-25.webp",
    logo: "game_pic/icon2/candy-forest-25.webp",
    image: "game_pic/icon3/candy-forest-25.webp",
    icon4: "game_pic/icon4/candy-forest-25.webp",
    icon5:
      "game_pic/icon_large/candy-forest-25.webp",
    icon6: "",
    playCount: "567272",
    rating: "4.5",
    description:
      "Candy Forest is a delightful match-3 game set in a whimsical forest. Your objective is to exchange the positions of candies on the board to create combinations of three or more identical candies. As you progress through the levels, the challenges become increasingly complex, requiring strategic thinking and careful planning to complete tasks. With colorful visuals and engaging gameplay, Candy Forest provides hours of fun for fans of match-3 puzzle games.",
    createdTime: "",
    url: "games/CandyForest/",
    icon2Large:
      "game_pic/icon2_large/candy-forest-25.webp",
    icon3Large:
      "game_pic/icon3_large/candy-forest-25.webp",
    categoryName: "",
    categoryId: ",64,85,",
    intro:
      "In Candy Forest, you engage in a classic match-3 game by swapping the positions of candies to eliminate three or more identical candies.",
    gamePlay:
      "Swap the positions of candies on the board to create matches of three or more identical candies and complete tasks.",
    likeRatio: "90",
    likeCount: "183796",
    dislikeCount: "20421",
    displayMethod: "",
    icon4Large:
      "game_pic/icon4_large/candy-forest-25.webp",
  },
];
